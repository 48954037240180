import React from 'react';

import { IconProps } from '../icons.types';

export const ErrorIcon: React.FC<IconProps> = (props) => (
  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <mask id='mask0_154_7754' maskUnits='userSpaceOnUse' x='1' y='1' width='23' height='23'>
      <ellipse cx='12.7092' cy='12.5' rx='11.0002' ry='11' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_154_7754)'>
      <rect x='0.708984' y='0.5' width='24.0004' height='24' fill='#FF616D' />
    </g>
    <path
      d='M12.1564 6.56003C12.3307 6.48327 12.5191 6.44372 12.7095 6.44388C12.8999 6.44392 13.0881 6.48364 13.2622 6.56048C13.4363 6.63732 13.5925 6.74961 13.7208 6.89018C13.8491 7.03075 13.9466 7.19652 14.0073 7.37691C14.0679 7.5573 14.0903 7.74835 14.073 7.93788L13.527 13.9469C13.5058 14.1489 13.4105 14.3359 13.2596 14.4718C13.1086 14.6078 12.9127 14.683 12.7095 14.683C12.5064 14.683 12.3105 14.6078 12.1595 14.4718C12.0085 14.3359 11.9133 14.1489 11.892 13.9469L11.3445 7.93788C11.3272 7.74823 11.3497 7.55705 11.4104 7.37656C11.4711 7.19606 11.5689 7.03022 11.6973 6.88963C11.8258 6.74904 11.9821 6.63678 12.1564 6.56003Z'
      fill='white'
    />
    <path
      d='M14.0181 17.2476C14.0181 17.9703 13.4323 18.5561 12.7096 18.5561C11.9869 18.5561 11.4011 17.9703 11.4011 17.2476C11.4011 16.525 11.9869 15.9392 12.7096 15.9392C13.4323 15.9392 14.0181 16.525 14.0181 17.2476Z'
      fill='white'
    />
  </svg>
);
