import { BillableMetricsCode } from '@prisma/client';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';

import { trackAlertDisplayed, trackItemClicked } from '../../analytics/tracking';
import { Banner, BannerType, BannerVariant, Icons } from '../../ui-library';
import { api } from '../../utils/api';

export const ActionLimitAlert = () => {
  const router = useRouter();

  const { data: planLimitsAndStatus } = api.PlansRouter.getPlanLimitsAndStatus.useQuery({
    billableMetricCode: BillableMetricsCode.actions_v1,
  });

  const used = planLimitsAndStatus?.consumptionLimits.used ?? 0;
  const limit = planLimitsAndStatus?.consumptionLimits.limit ?? 0;
  const currentLimit = useMemo(() => Math.round((used / limit) * 100 * 10) / 10, [used, limit]);

  const isWithinLimit = currentLimit >= 75 && currentLimit < 100;
  const isLimitExceeded = currentLimit >= 100;

  const bannerTitle = useMemo(() => {
    if (isWithinLimit) {
      return `You are close to the limit`;
    }
    return `You reached the limit`;
  }, [isWithinLimit]);

  const bannerDescription = useMemo(() => {
    if (isWithinLimit) {
      return `You have used ${currentLimit}% of your Actions for this month. Upgrade to get more Actions here.`;
    }
    return `You have used all your Actions for this month. Upgrade to get more Actions here.`;
  }, [isWithinLimit, currentLimit]);

  const bannerType = isWithinLimit ? BannerType.Warning : BannerType.Error;

  const onUpgradeClickHandler = () => {
    trackItemClicked({
      elementId: 'alert-buy-more-actions-fe',
      elementType: 'button',
    });

    router.push('/plans');
  };

  useEffect(() => {
    if (isWithinLimit || isLimitExceeded) {
      trackAlertDisplayed({
        elementId: 'plan-actions-limit-fe',
        elementType: isWithinLimit ? 'warning' : 'danger',
        elementValue: {
          current_value: used,
          limit,
        },
      });
    }
  }, [isWithinLimit, isLimitExceeded, used, limit]);

  if (!isWithinLimit && !isLimitExceeded) {
    return null;
  }

  return (
    <Banner
      icon={isWithinLimit ? <Icons.Alert /> : <Icons.Prohibit />}
      title={bannerTitle}
      description={bannerDescription}
      type={bannerType}
      variant={BannerVariant.Condensed}
      primaryButton={{
        text: 'Buy more actions',
        onClick: onUpgradeClickHandler,
      }}
    />
  );
};
