import { Switch as HeadlessSwitch } from '@headlessui/react';
import React from 'react';

import { cn } from '@/utils/styles';

import { getSwitchKnobSize, getSwitchKnobTranslateX, getSwitchSize } from './switch.core';
import { SwitchLabelPosition, type SwitchProps, SwitchSize } from './switch.types';

export const Switch: React.FC<SwitchProps> = (props) => {
  const {
    checked = false,
    onChange,
    disabled = false,
    labelText,
    labelPosition = SwitchLabelPosition.RIGHT,
    size = SwitchSize.MEDIUM,
  } = props;

  const switchSizeClassNames = getSwitchSize(size);
  const switchKnobSizeClassNames = getSwitchKnobSize(size);
  const switchKnobTranslateXClassnames = getSwitchKnobTranslateX(checked, size);

  return (
    <HeadlessSwitch.Group>
      <div
        className={cn(`flex items-center gap-4`, {
          'flex-row-reverse': labelPosition === SwitchLabelPosition.LEFT,
        })}>
        <HeadlessSwitch
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          className={cn(
            'relative inline-flex items-center flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-NeutralLightDark disabled:border-NeutralLightDark disabled:ring-0 disabled:ring-offset-0 disabled:ring-transparent',
            checked ? 'bg-HighlightDarkest' : 'bg-NeutralLightDark',
            switchSizeClassNames,
          )}>
          <div
            aria-hidden='true'
            className={cn(
              'pointer-events-none inline-block transform rounded-full bg-NeutralLightLightest ring-0 transition duration-200 ease-in-out',
              switchKnobTranslateXClassnames,
              switchKnobSizeClassNames,
            )}
          />
        </HeadlessSwitch>
        {labelText && (
          <HeadlessSwitch.Label
            className={cn(
              'w-fit transition duration-200 ease-in-out text-[13px]',
              checked ? 'text-HighlightDarkest' : 'text-NeutralLightDarkest',
            )}>
            {labelText}
          </HeadlessSwitch.Label>
        )}
      </div>
    </HeadlessSwitch.Group>
  );
};
