import React from 'react';

import { IconProps } from '../icons.types';

export const PathIcon: React.FC<IconProps> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <path
      d='M2 7H3.32621C3.91197 7 4.48927 7.09771 5.01011 7.285C5.53095 7.47229 5.98028 7.74375 6.32075 8.07682L8.71926 10.4232C9.05972 10.7562 9.50906 11.0277 10.0299 11.215C10.5507 11.4023 11.128 11.5 11.7138 11.5H13.5'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 2.5L13.62 4.12L12 5.74'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 10L13.62 11.62L12 13.24'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7 6.5C7.22355 6.5 7.96297 6.25 8.49598 5.67505C8.85235 5.15695 9.32267 4.73467 9.86783 4.44333C10.413 4.15199 11.0173 4 11.6304 4H13.5'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
