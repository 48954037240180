import React from 'react';

import { Button, ButtonVariant } from '@/ui-library';

import { TestFlowStatus } from '../../../utils/types/test-flow.types';

export const ZendeskUpdateTags = ({
  onCancel,
  status,
}: {
  data?: { categories: string[] };
  onCancel: () => void;
  onConfirm: () => void;
  status: TestFlowStatus;
}) => {
  if (status === TestFlowStatus.WARNING) {
    return (
      <>
        <h2 className='text-NeutralDarkDarkest text-base not-italic font-black mb-5'>
          Your flow executed properly, but...
        </h2>
        <div className='flex flex-col gap-2 justify-center mb-8'>
          <p className='text-NeutralDarkLight mb-3'>
            No labels or mail were found to classify. Try to add more labels in the AI Block and try again.
          </p>
          <div className='w-full flex gap-2 justify-center'>
            <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
              Got it
            </Button>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <h2 className='text-NeutralDarkDarkest text-lg not-italic font-black mb-5'>{`We've updated the ticket tag for you`}</h2>
      <p className='text-NeutralDarkLight mb-3'>Check your ticket in Zendesk to see updated tag</p>
      <div className='w-full flex gap-2 justify-center'>
        <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
          Close
        </Button>
      </div>
    </>
  );
};
