import React from 'react';

import { cn } from '@/utils/styles';

import type { CollectionAddItemProps } from '../collection.types';

export const CollectionAddItem: React.FC<CollectionAddItemProps> = (props) => {
  const { text, onClick, className, ...rest } = props;

  return (
    <button
      type='button'
      onClick={onClick}
      className={cn(
        'group/list-item flex gap-4 cursor-pointer flex-row items-center justify-center text-[13px] text-NeutralDarkMedium font-medium leading-4 hover:text-HighlightDarkest border border-dashed border-HighlightLight hover:border-HighlightDarkest rounded-2xl bg-HighlightLightest p-4',
        className,
      )}
      {...rest}>
      {text}
    </button>
  );
};
