import React from 'react';

export const QuestionIcon = () => (
  <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.5 1.40625C6.29477 1.40625 5.11661 1.76364 4.1145 2.43323C3.11238 3.10282 2.33133 4.05454 1.87011 5.16802C1.40889 6.28151 1.28821 7.50676 1.52334 8.68883C1.75847 9.8709 2.33884 10.9567 3.19107 11.8089C4.0433 12.6612 5.1291 13.2415 6.31117 13.4767C7.49324 13.7118 8.71849 13.5911 9.83198 13.1299C10.9455 12.6687 11.8972 11.8876 12.5668 10.8855C13.2364 9.88339 13.5938 8.70523 13.5938 7.5C13.592 5.88436 12.9495 4.33538 11.807 3.19295C10.6646 2.05052 9.11564 1.40796 7.5 1.40625ZM7.5 11.25C7.36094 11.25 7.225 11.2088 7.10937 11.1315C6.99374 11.0542 6.90362 10.9444 6.8504 10.8159C6.79718 10.6875 6.78326 10.5461 6.81039 10.4097C6.83752 10.2733 6.90448 10.148 7.00282 10.0497C7.10115 9.95136 7.22644 9.88439 7.36283 9.85726C7.49922 9.83013 7.6406 9.84405 7.76908 9.89727C7.89756 9.95049 8.00737 10.0406 8.08463 10.1562C8.16189 10.2719 8.20313 10.4078 8.20313 10.5469C8.20313 10.7334 8.12905 10.9122 7.99719 11.0441C7.86533 11.1759 7.68648 11.25 7.5 11.25ZM7.96875 8.39531V8.4375C7.96875 8.56182 7.91937 8.68105 7.83146 8.76896C7.74355 8.85686 7.62432 8.90625 7.5 8.90625C7.37568 8.90625 7.25645 8.85686 7.16855 8.76896C7.08064 8.68105 7.03125 8.56182 7.03125 8.4375V7.96875C7.03125 7.84443 7.08064 7.7252 7.16855 7.63729C7.25645 7.54939 7.37568 7.5 7.5 7.5C8.2752 7.5 8.90625 6.97266 8.90625 6.32812C8.90625 5.68359 8.2752 5.15625 7.5 5.15625C6.72481 5.15625 6.09375 5.68359 6.09375 6.32812V6.5625C6.09375 6.68682 6.04437 6.80605 5.95646 6.89396C5.86855 6.98186 5.74932 7.03125 5.625 7.03125C5.50068 7.03125 5.38145 6.98186 5.29355 6.89396C5.20564 6.80605 5.15625 6.68682 5.15625 6.5625V6.32812C5.15625 5.16504 6.20742 4.21875 7.5 4.21875C8.79258 4.21875 9.84375 5.16504 9.84375 6.32812C9.84375 7.34648 9.0375 8.19902 7.96875 8.39531Z'
      fill='#C5C6CC'
    />
  </svg>
);
