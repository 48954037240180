import type { CustomContentProps } from 'notistack';

export enum ToastType {
  Informative = 'Informative',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
}

export interface ToastProps extends Partial<CustomContentProps> {
  title?: string;
  description?: string;
  type?: ToastType;
}
