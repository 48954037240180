import Link from 'next/link';

import { cn } from '@/utils/styles';

import type { NavItemProps } from '../types';

export const NavItem: React.FC<NavItemProps> = (props) => {
  const {
    item: { name, icon, href, current },
    isCompact,
  } = props;

  return (
    <Link
      href={href}
      className={cn(
        'truncate max-h-[50px] px-2 gap-2.5 flex items-center py-4 text-sm leading-4 font-medium rounded',
        isCompact && 'justify-center',
        current
          ? 'text-HighlightDarkest bg-[#F6F6FA]'
          : 'text-NeutralDarkDarkest hover:text-HighlightDarkest hover:bg-[#F6F6FA] transition-all ease-in-out',
      )}
      title={isCompact ? `${name}` : ''}>
      <div>{icon}</div>
      <span className={cn(isCompact ? 'hidden' : 'flex')}>{name}</span>
    </Link>
  );
};
