import React from 'react';

import { IconProps } from '../icons.types';

export const SparkleIcon: React.FC<IconProps> = (props) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.75384 12.3372L8.40266 16.0047C8.36288 16.1127 8.29096 16.2058 8.19658 16.2716C8.1022 16.3375 7.9899 16.3727 7.87484 16.3727C7.75978 16.3727 7.64749 16.3375 7.55311 16.2716C7.45873 16.2058 7.3868 16.1127 7.34703 16.0047L5.99584 12.3372C5.96771 12.2608 5.92333 12.1915 5.86577 12.1339C5.80822 12.0764 5.73886 12.032 5.66249 12.0038L1.99499 10.6527C1.88703 10.6129 1.79386 10.541 1.72805 10.4466C1.66224 10.3522 1.62695 10.2399 1.62695 10.1248C1.62695 10.0098 1.66224 9.89749 1.72805 9.80311C1.79386 9.70873 1.88703 9.6368 1.99499 9.59703L5.66249 8.24584C5.73886 8.21771 5.80822 8.17333 5.86577 8.11577C5.92333 8.05822 5.96771 7.98886 5.99584 7.91249L7.34703 4.24499C7.3868 4.13703 7.45873 4.04386 7.55311 3.97805C7.64749 3.91224 7.75978 3.87695 7.87484 3.87695C7.9899 3.87695 8.1022 3.91224 8.19658 3.97805C8.29096 4.04386 8.36288 4.13703 8.40266 4.24499L9.75384 7.91249C9.78198 7.98886 9.82636 8.05822 9.88391 8.11577C9.94147 8.17333 10.0108 8.21771 10.0872 8.24584L13.7547 9.59703C13.8627 9.6368 13.9558 9.70873 14.0216 9.80311C14.0875 9.89749 14.1227 10.0098 14.1227 10.1248C14.1227 10.2399 14.0875 10.3522 14.0216 10.4466C13.9558 10.541 13.8627 10.6129 13.7547 10.6527L10.0872 12.0038C10.0108 12.032 9.94147 12.0764 9.88391 12.1339C9.82636 12.1915 9.78198 12.2608 9.75384 12.3372V12.3372Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12.375 1.125V4.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M14.0625 2.8125H10.6875'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.75 5.0625V7.3125'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.875 6.1875H14.625'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
