import React from 'react';

import { Banner, Icons } from '@/ui-library';

export const ContinueOnDesktopBanner = () => (
  <div className='sticky z-50 w-full sm:hidden'>
    <Banner
      title='Continue on desktop'
      description='Our app is not optimized for mobile yet. 
        Continue on desktop for an optimal experience.'
      icon={<Icons.Laptop />}
    />
  </div>
);
