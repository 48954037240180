import React from 'react';

import { Button, ButtonVariant } from '@/ui-library';

import type { TestFlowStatus } from '../../utils/types/test-flow.types';

export const NetworkHttpRequest = ({
  onCancel,
}: {
  data?: string[];
  onCancel: () => void;
  onConfirm: () => void;
  status?: TestFlowStatus;
}) => (
  <>
    <h2 className='text-NeutralDarkDarkest text-lg not-italic font-black mb-5'>An HTTP request has been triggered</h2>
    <p className='text-NeutralDarkLight mb-3'>Check the details of the call in your server logs</p>
    <div className='w-full flex gap-2 justify-center'>
      <Button variant={ButtonVariant.Primary} onClick={onCancel}>
        Done
      </Button>
    </div>
  </>
);
