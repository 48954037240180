import { CopyTagTypes, OperationDefinition } from '../../../../flows/types';
import { TAG } from '../../constants';

type HubspotOperationKeys = 'NewTicket' | 'UpdateTicketProperties';

export const hubspotOperationsDefinition: Record<HubspotOperationKeys, OperationDefinition> = {
  NewTicket: {
    id: 'hubspot-get-tickets',
    name: 'New Ticket',
    description: 'New ticket received',
    icon: 'hubspotV1',
    hasDynamicInputs: false,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'When ticket is received',
      },
      actionDefinition: {
        elementTags: ['New ticket'],
        action: `When ${TAG} is received`,
      },
    },
  },
  UpdateTicketProperties: {
    id: 'hubspot-update-ticket-properties',
    name: 'Update properties',
    description: 'Update ticket properties',
    icon: 'hubspotV1',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Update Properties',
        tooltips: {
          description: 'Choose a property of your incoming ticket in Hubspot.',
        },
      },
      interactiveParameters: {
        label: 'Property name',
        title: 'Property name',
        tooltips: {
          description: 'Choose a value to update the property of your incoming ticket in Hubspot.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Update ticket properties`,
      },
    },
  },
};
