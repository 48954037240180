import React from 'react';

import { Button, ButtonVariant } from '@/ui-library';
import { TestFlowStatus } from '@/utils/types/test-flow.types';

import CodeBlock from '../aiblock-modal/connect-api.component';

export const LoggerBlock = ({
  data,
  onCancel,
  status,
}: {
  data?: { loggedResult: string };
  onCancel: () => void;
  onConfirm: () => void;
  status: TestFlowStatus;
}) => {
  if (status === TestFlowStatus.WARNING) {
    return (
      <>
        <h2 className='text-NeutralDarkDarkest text-base not-italic font-black mb-5'>
          {`We've logged your information, but the flow had some warnings`}
        </h2>
        {data?.loggedResult && (
          <div className='flex flex-col gap-2 justify-center mb-8'>
            <p>You can check it in the logger system.</p>
            <CodeBlock code={JSON.parse(data?.loggedResult)} />
          </div>
        )}
        <div className='w-full flex gap-2 justify-center'>
          <Button variant={ButtonVariant.Primary} onClick={onCancel}>
            Done
          </Button>
        </div>
      </>
    );
  }
  return (
    <>
      <h2 className='text-NeutralDarkDarkest text-base not-italic font-black mb-5'>
        {`We've logged your information`}
      </h2>
      {data?.loggedResult && (
        <div className='flex flex-col gap-2 justify-center mb-8'>
          <p>You can check it in the logger system.</p>
          <CodeBlock code={JSON.parse(data?.loggedResult || '')} />
        </div>
      )}
      <div className='w-full flex gap-2 justify-center'>
        <Button variant={ButtonVariant.Primary} onClick={onCancel}>
          Done
        </Button>
      </div>
    </>
  );
};
