import React from 'react';

import { IconProps } from '../icons.types';

export const MagicWandIcon: React.FC<IconProps> = (props) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M27 16V22' stroke='#1F2024' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M24 19H30' stroke='#1F2024' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.5 5V10' stroke='#1F2024' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8 7.5H13' stroke='#1F2024' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M21 23V27' stroke='#1F2024' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M19 25H23' stroke='#1F2024' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M23.2929 4.70711L4.70711 23.2929C4.31658 23.6834 4.31658 24.3166 4.70711 24.7071L7.29289 27.2929C7.68342 27.6834 8.31658 27.6834 8.70711 27.2929L27.2929 8.70711C27.6834 8.31658 27.6834 7.68342 27.2929 7.29289L24.7071 4.70711C24.3166 4.31658 23.6834 4.31658 23.2929 4.70711Z'
      stroke='#1F2024'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M18 10L22 14' stroke='#1F2024' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
