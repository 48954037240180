import React from 'react';

import { IconProps } from '../icons.types';

export const CheckCircleIcon: React.FC<IconProps> = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <mask id='mask0_7383_16530' maskUnits='userSpaceOnUse' x='0' y='1' width='23' height='22'>
      <ellipse cx='11.9998' cy='12' rx='11.0002' ry='11' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_7383_16530)'>
      <rect x='-0.00038147' width='24.0004' height='24' fill='#3AC0A0' />
    </g>
    <path
      d='M16.7915 8.8313C16.4023 8.41205 15.757 8.40552 15.3601 8.81791L10.8902 13.4619L8.64 11.124C8.24307 10.7117 7.59778 10.7182 7.20853 11.1374C6.82895 11.5463 6.83419 12.1965 7.22119 12.5986L10.8902 16.4104L16.7789 10.2924C17.1659 9.89035 17.1711 9.24013 16.7915 8.8313Z'
      fill='white'
    />
  </svg>
);
