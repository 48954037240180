import React from 'react';

import { cn } from '@/utils/styles';

import { getBgColor } from '../app-icon.core';
import { AppIconColourVariant, type AppIconContainerProps } from '../app-icon.types';

export const AppIconContainer: React.FC<AppIconContainerProps> = (props) => {
  const { isPlaceholder = false, colourVariant = AppIconColourVariant.DEFAULT, children, className, ...rest } = props;

  const sizeStyles = 'h-10 w-10';
  const bgStyles = getBgColor(colourVariant, isPlaceholder);
  const borderStyles = isPlaceholder ? 'border border-dashed border-[#B7B8ED]' : '';

  return (
    <div
      className={cn(
        'flex items-center justify-center text-lg p-2 rounded-lg',
        sizeStyles,
        bgStyles,
        borderStyles,
        className,
      )}
      data-testid='app-icon-container'
      {...rest}>
      {children}
    </div>
  );
};
