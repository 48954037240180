import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CircleLoader from 'react-spinners/ClipLoader';

import { QuestionIcon, Tooltip } from '@/ui-library/tooltip';
import { cn } from '@/utils/styles';

import { Label } from '../label';
import { SupportText } from '../support-text';
import {
  MultiSelectDropdownHeight,
  MultiSelectDropdownOption,
  MultiSelectDropdownProps,
} from './multi-select-dropdown.types';

const MultiSelectDropdownRaw: React.FC<MultiSelectDropdownProps> = ({
  name,
  options,
  placeholder,
  isError = false,
  disabled = false,
  className = '',
  loading = false,
  height = MultiSelectDropdownHeight.Medium,
  value: controlledValue,
  ...props
}) => {
  const { setValue, watch } = useFormContext();
  const selectedValues = watch(name) || [];

  const [query] = useState('');

  const toggleSelectedValue = (option: MultiSelectDropdownOption) => {
    let newSelectedValues: MultiSelectDropdownOption[] = [...selectedValues];
    const selectedIds = newSelectedValues.map((val) => val.id);
    if (selectedIds.includes(option.id)) {
      newSelectedValues = newSelectedValues.filter((val) => val.id !== option.id);
    } else {
      newSelectedValues.push(option);
    }
    setValue(name, newSelectedValues);
    props.onChange?.(newSelectedValues);
  };

  const filteredValues =
    query === '' ? options : options.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()));

  const baseStyle = cn(
    'bg-white block w-full resize-y border-0 px-4 placeholder:text-NeutralDarkLightest outline-none text-sm',
    disabled || loading ? 'bg-NeutralLightLight text-NeutralLightDarkest' : 'text-NeutralDarkDarkest',
    height === MultiSelectDropdownHeight.Small ? 'h-[34px] py-0.5' : 'h-12 py-3.5',
  );
  const ringStyles = (isOpen: boolean) =>
    isError
      ? 'ring-inset ring-SupportErrorMedium ring-[1.5px]'
      : cn(
          'ring-inset ring-1 ring-NeutralLightDarkest focus:ring-HighlightMedium focus:ring-[1.5px]',
          !disabled && !loading && 'hover:ring-HighlightMedium hover:ring-[1.5px]',
          isOpen ? 'ring-HighlightMedium ring-[1.5px]' : '',
        );

  const roundedStyles = (isOpen: boolean) => cn(isOpen ? 'rounded-t-lg rounded-x-lg' : 'rounded-lg');

  const inputClassName = (isOpen: boolean) => `${baseStyle} ${ringStyles(isOpen)} ${className}`;
  const textDisplay = selectedValues.length > 0 ? '' : 'text-NeutralDarkLightest';

  const isChecked = (optionId: string) => {
    const ids = selectedValues.map((val: MultiSelectDropdownOption) => val.id);
    return ids.includes(optionId);
  };

  return (
    <Listbox value={selectedValues} multiple disabled={disabled || loading}>
      {({ open }) => (
        <div>
          <Listbox.Button className={`w-full ${inputClassName(open)} ${roundedStyles(open)}`}>
            <div className='relative flex justify-between items-center'>
              {loading && selectedValues.length === 0 ? (
                <div className='absolute flex items-center justify-center h-full gap-2'>
                  <CircleLoader color='#5557F6' size={20} cssOverride={{ borderWidth: '2px' }} />
                  <span className='text-NeutralDarkLightest  text-sm'>Loading...</span>
                </div>
              ) : null}
              <span className={cn('truncate', textDisplay)}>
                {selectedValues.map((option: MultiSelectDropdownOption) => option?.label).join(', ') ||
                  (loading ? '' : placeholder ?? 'Select an option...')}
              </span>
              <div
                className={cn(
                  'w-5 h-5 mr-1 shrink-0',
                  disabled || loading ? 'text-NeutralLightDarkest' : 'text-NeutralDarkLightest',
                )}>
                {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </div>
            </div>
          </Listbox.Button>
          <Listbox.Options className='z-10 -mt-1 w-full overflow-hidden bg-white text-base focus:outline-none sm:text-sm ring-inset ring-HighlightMedium ring-[1.5px] p-3 rounded-b-lg'>
            <div className='overflow-y-auto max-h-60'>
              {filteredValues.map((option) => (
                <Listbox.Option key={option.id} value={option} as={Fragment}>
                  {({ active }) => (
                    <li
                      className={`flex items-center cursor-pointer select-none text-sm relative px-3 py-2 text-NeutralDarkMedium ${
                        active ? 'bg-NeutralLightLight' : ''
                      }`}>
                      <button
                        className='flex items-center w-full text-left'
                        onClick={(e) => {
                          e.preventDefault();
                          toggleSelectedValue(option);
                        }}>
                        {/* Custom Checkbox */}
                        <div
                          role='checkbox'
                          aria-checked={isChecked(option.id) ? 'true' : 'false'}
                          tabIndex={0}
                          className={`cursor-pointer mr-2.5 w-4 h-4 flex justify-center items-center ${
                            isChecked(option.id)
                              ? 'bg-HighlightDarkest border-HighlightDarkest'
                              : 'border-NeutralLightDarkest bg-transparent'
                          } border-[1.5px] rounded-[4px]`}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}>
                          {isChecked(option.id) && <CheckIcon className='w-3 h-3 text-white' />}
                        </div>
                        <input
                          type='checkbox'
                          className='opacity-0 absolute'
                          checked={isChecked(option.id)}
                          onChange={() => {}}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleSelectedValue(option);
                          }}
                          tabIndex={-1}
                        />
                        {option.label}
                      </button>
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </div>
          </Listbox.Options>
        </div>
      )}
    </Listbox>
  );
};

export const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  name,
  label,
  options,
  tooltip,
  supportText,
  disabled,
  className,
  isOptional,
  labelAction,
  height = MultiSelectDropdownHeight.Medium,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message as string;
  const isError = Boolean(errorMessage);

  return (
    <div className='flex flex-col gap-2'>
      {label && (
        <div className='flex items-center gap-1 '>
          <Label htmlFor={name}>
            <div className='flex items-center gap-1'>
              {label}
              {isOptional && <span className='font-normal text-NeutralDarkLightest'>Optional</span>}
              {labelAction && (
                <button onClick={labelAction.onAction} className='ml-1 font-medium text-HighlightDarkest'>
                  {labelAction.text}
                </button>
              )}
            </div>
          </Label>
          {tooltip && (
            <Tooltip content={tooltip}>
              <QuestionIcon />
            </Tooltip>
          )}
        </div>
      )}
      <MultiSelectDropdownRaw
        name={name}
        options={options}
        disabled={disabled}
        isError={isError}
        height={height}
        className={className}
        {...props}
      />
      {supportText || errorMessage ? <SupportText text={errorMessage ?? supportText} isError={isError} /> : null}
    </div>
  );
};
