import React from 'react';

import { IconProps } from '../icons.types';

export const DisconnectIcon: React.FC<IconProps> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <path d='M6 4.5V3' stroke='currentColor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10 13V11.5' stroke='currentColor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4.5 6H3' stroke='currentColor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M13 10H11.5' stroke='currentColor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M4.4393 8.02515L3.7322 8.73225C3.26359 9.20114 3.00039 9.83696 3.00049 10.4999C3.00059 11.1628 3.26397 11.7985 3.73272 12.2673C4.20147 12.736 4.8372 12.9994 5.50012 12.9995C6.16303 12.9996 6.79884 12.7364 7.26773 12.2678L7.97484 11.5607'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.5604 7.97484L12.2675 7.26773C12.7362 6.79884 12.9994 6.16303 12.9993 5.50012C12.9992 4.8372 12.7358 4.20147 12.267 3.73272C11.7983 3.26397 11.1625 3.00059 10.4996 3.00049C9.83671 3.00039 9.2009 3.26359 8.73201 3.7322L8.0249 4.4393'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
