import React from 'react';

import { IconProps } from '../icons.types';

export const CloseIcon: React.FC<IconProps> = (props) => (
  <svg
    className='group cursor-pointer'
    width='13'
    height='13'
    viewBox='0 0 13 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <mask id='mask0_154_7740' maskUnits='userSpaceOnUse' x='1' y='1' width='11' height='11'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.67865 1.46979C1.97155 1.1769 2.44642 1.1769 2.73931 1.46979L11.7393 10.4696C12.0322 10.7625 12.0322 11.2374 11.7393 11.5303C11.4464 11.8232 10.9715 11.8232 10.6787 11.5303L1.67865 2.53043C1.38576 2.23754 1.38576 1.76268 1.67865 1.46979Z'
        className='fill-[#71727A]'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7393 1.46979C11.4464 1.1769 10.9715 1.1769 10.6787 1.46979L1.67865 10.4696C1.38576 10.7625 1.38576 11.2374 1.67865 11.5303C1.97155 11.8232 2.44642 11.8232 2.73932 11.5303L11.7393 2.53043C12.0322 2.23754 12.0322 1.76268 11.7393 1.46979Z'
        className='fill-[#71727A]'
      />
    </mask>
    <g mask='url(#mask0_154_7740)'>
      <rect x='0.708984' y='0.500122' width='12' height='12' className='fill-[#2d2e31] group-hover:fill-[#000000]' />
    </g>
  </svg>
);
