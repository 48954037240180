import { useAutoAnimate } from '@formkit/auto-animate/react';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { POSTHOG_URL } from '@/analytics/constants/constants';
import { useFeatureFlags } from '@/levity/feature-flags';
import { env } from '@/lib/env';
import { api } from '@/utils/api';
import { ENVIRONMENTS } from '@/utils/constants';

import { useIdentifyUser } from '../analytics';
import { initializeEvents } from '../analytics/tracking';
import { useAuthSession } from '../levity/auth/compatibility-components';
import { LoadingLogo } from './loading-logo';

export interface EventLayerProps {
  children: ReactNode;
}
export const EventLayer = ({ children }: EventLayerProps) => {
  const session = useAuthSession();

  const [posthogInitialized, setPosthogInitialized] = useState(false);
  const { featureFlags, setFeatureFlags } = useFeatureFlags();

  useIdentifyUser(posthogInitialized, session);

  const [animationParent] = useAutoAnimate();

  useEffect(() => {
    initializeEvents({
      apiKey: env.NEXT_PUBLIC_POSTHOG_API!,
      apiUrl:
        env.NEXT_PUBLIC_NODE_ENV! === ENVIRONMENTS.DEVELOPMENT ? POSTHOG_URL : env.NEXT_PUBLIC_ANALYTICS_ENDPOINT!,
    });

    setPosthogInitialized(true);
  }, []);

  const { isLoading } = api.AnalyticsRouter.getFeatureFlags.useQuery(
    {},
    {
      enabled: !featureFlags && posthogInitialized,
      onSuccess: (data) => {
        if (data && setFeatureFlags) setFeatureFlags(data);
      },
    },
  );

  return (
    <div ref={animationParent}>
      {isLoading && (
        <div className='flex h-full w-full items-center justify-center bg-NeutralLightLight'>
          <LoadingLogo />
          <span className='sr-only'>Loading...</span>
        </div>
      )}
      {!isLoading && <>{children}</>}
    </div>
  );
};
