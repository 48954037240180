import React from 'react';

import { Icons } from '../../icons';
import type { AppIconPlaceholderProps } from '../app-icon.types';
import { AppIconContainer } from './app-icon-container.component';

export const AppIconPlaceholder: React.FC<AppIconPlaceholderProps> = (props) => {
  const { size, colourVariant } = props;

  return (
    <AppIconContainer isPlaceholder={true} size={size} colourVariant={colourVariant}>
      <Icons.Lightning />
    </AppIconContainer>
  );
};
