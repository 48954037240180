import type { HTMLAttributes, MouseEventHandler, ReactNode } from 'react';

import type { ButtonProps } from '../button';

export enum BannerType {
  Informative = 'Informative',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
}

export enum BannerVariant {
  Default = 'Default',
  Condensed = 'Condensed',
}
export interface BannerProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  description?: string;
  icon?: ReactNode;
  onDismiss?: MouseEventHandler<HTMLButtonElement>;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  type?: BannerType;
  variant?: BannerVariant;
}
