import React from 'react';

import { cn } from '@/utils/styles';

import { CircularProgressBar } from '../../components/progress-bar.component';
import { getProgressbarColor, getSizeStyles, getVariantStyles } from './button.core';
import type { ButtonProps } from './button.types';
import { ButtonIconPosition, ButtonSize, ButtonVariant } from './button.types';

export const Button: React.FC<ButtonProps> = ({
  text,
  icon,
  variant = ButtonVariant.Primary,
  size = ButtonSize.Default,
  iconPosition = ButtonIconPosition.Left,
  isLoading = false,
  className = '',
  children,
  ...props
}) => {
  const baseStyle =
    'group inline-flex tracking-normal gap-2 w-fit items-center justify-center border-[1.5px] font-semibold rounded-[10px] focus:outline-none';

  const variantStyles = getVariantStyles(variant);

  const sizeStyles = getSizeStyles(size);

  const progressBarSize = size === ButtonSize.Large ? 15 : 12;

  const progressBarColor = getProgressbarColor(variant);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button type='button' className={cn(baseStyle, variantStyles, sizeStyles, className)} {...props}>
      {isLoading && <CircularProgressBar size={progressBarSize} strokeWidth={1.5} color={progressBarColor} />}
      {icon && !isLoading && iconPosition === ButtonIconPosition.Left && <span>{icon}</span>}
      {children || text}
      {icon && iconPosition === ButtonIconPosition.Right && <span>{icon}</span>}
    </button>
  );
};
