import type { FC, ReactNode } from 'react';
import React from 'react';

import { cn } from '@/utils/styles';

import { Button } from '../button';
import { backgroundColor, getStyle } from './banner.core';
import type { BannerProps } from './banner.types';
import { BannerType, BannerVariant } from './banner.types';

export const Banner: FC<BannerProps> = ({
  title,
  description,
  children,
  icon,
  primaryButton,
  secondaryButton,
  type = BannerType.Informative,
  variant = BannerVariant.Default,
}) => {
  /** Styles */
  const bgColorClassName = backgroundColor(type);
  const {
    baseClassName,
    descriptionBaseClassName,
    titleClassName,
    descriptionClassName,
    iconBaseClassName,
    ctaContainerClassName,
  } = getStyle(variant);

  const defaultContent: ReactNode = (
    <div className={descriptionBaseClassName}>
      {title && (
        <div className={iconBaseClassName}>
          {icon}
          <h5 className={titleClassName}>{title}</h5>
        </div>
      )}
      {description && <p className={descriptionClassName}>{description}</p>}
    </div>
  );

  return (
    <div className={cn(bgColorClassName, baseClassName)}>
      {children || defaultContent}
      {(primaryButton || secondaryButton) && (
        <div className={ctaContainerClassName}>
          {secondaryButton && <Button {...secondaryButton} />}
          {primaryButton && <Button {...primaryButton} />}
        </div>
      )}
    </div>
  );
};
