import React from 'react';

export const CircularProgressBar = ({ size = 40, strokeWidth = 4, color = 'text-white' }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 5 * Math.PI * radius;
  const strokeDashoffset = circumference - circumference / 4;

  return (
    <svg className='animate-spin' width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle
        className={`${color} stroke-current`}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset }}
        strokeWidth={strokeWidth}
        r={radius}
        cx={size / 2}
        cy={size / 2}
        fill='transparent'
      />
    </svg>
  );
};
