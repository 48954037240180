import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useEffect } from 'react';
import type { FieldValues, Path } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';

import { ComboBox, Input, MultiSelectDropdown, TextArea } from './components';
import { delay } from './form.core';
import type { FormProps } from './form.types';

export function Form<T extends FieldValues>({
  children,
  defaultValues,
  onSubmit,
  autoFocus,
  schema,
  className,
  id,
}: FormProps<T>) {
  const methods = useForm<T>({
    defaultValues,
    resolver: schema ? zodResolver(schema) : undefined,
  });

  const { handleSubmit, reset, setFocus } = methods;

  const submitHandler = handleSubmit((data) => {
    onSubmit(data, { reset });
  });

  // Autofocus form field (optional)
  const autoFocusField = useCallback(
    async (autoFocus: Path<T>) => {
      await delay(0); // Delay needed because focus otherwise not working on navigation without refresh
      setFocus(autoFocus);
    },
    [setFocus],
  );

  useEffect(() => {
    if (autoFocus) autoFocusField(autoFocus);
  }, [autoFocusField, autoFocus]);

  useEffect(() => {
    reset(defaultValues as T);
  }, [defaultValues, reset]);

  return (
    <FormProvider {...methods}>
      <form id={id} onSubmit={submitHandler} className={className}>
        {children}
      </form>
    </FormProvider>
  );
}

Form.Input = Input;
Form.TextArea = TextArea;
Form.ComboBox = ComboBox;
Form.MultiSelectDropdown = MultiSelectDropdown;
