'use client';

import { type NextPage } from 'next';
import { useRouter } from 'next/router';
import React from 'react';
import { type PropsWithChildren } from 'react';

import { ActionLimitAlert } from '@/components/banner/action-limit.component';
import { ContinueOnDesktopBanner } from '@/components/banner/continue-on-desktop-banner.component';
import { EventLayer } from '@/components/events.layer';
import { MAIN_NAV, setCurrentNavItem, Sidebar, USER_MENU } from '@/components/sidebar';
import { useAuthSession } from '@/levity/auth/compatibility-components';
import { Provider as FeatureFlagsProvider } from '@/levity/feature-flags';

export default function SidebarLayout({ children }: PropsWithChildren) {
  const session = useAuthSession();
  const { pathname } = useRouter();

  const mainNavItems = setCurrentNavItem(pathname, MAIN_NAV);

  return (
    <FeatureFlagsProvider>
      <EventLayer>
        {session?.user && (
          <div className='h-screen w-screen flex overflow-hidden bg-NeutralLightLight antialiased'>
            <Sidebar mainNavItems={mainNavItems} userMenuItems={USER_MENU} user={session.user} />

            <main className='h-full w-full overflow-y-scroll ml-[55px] md:ml-0'>
              <ActionLimitAlert />
              <ContinueOnDesktopBanner />
              {children}
            </main>
          </div>
        )}
      </EventLayer>
    </FeatureFlagsProvider>
  );
}

// TODO: Remove if possible (Check if still needed)
export const NoLayout = (props: PropsWithChildren) => (
  // eslint-disable-next-line react/destructuring-assignment
  <div>{props.children}</div>
);

export const Layouts = {
  SidebarLayout,
  NoLayout,
};

export enum LayoutTypes {
  SidebarLayout = 'SidebarLayout',
  NoLayout = 'NoLayout',
}

export type LayoutKeys = keyof typeof Layouts;

export type LayoutPage<P, IP = P> = NextPage<P, IP> & {
  Layout?: LayoutKeys;
};
