import React from 'react';

interface DividerProps {
  height?: number;
  color?: string;
}

export const Divider: React.FC<DividerProps> = ({ height, color = 'slate-200' }) => (
  <div className={`w-full ${height ? `border-b-${height}` : 'border-b-[1px]'} border-${color}`} />
);
