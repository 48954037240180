import type React from 'react';
import { useEffect, useRef } from 'react';

export const useFocus = <T extends HTMLInputElement | HTMLTextAreaElement>(): React.RefObject<T> => {
  const ref = useRef<T>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return ref;
};
