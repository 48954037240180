import dayjs from 'dayjs';
import { decode as decodeJwt, JwtPayload } from 'jsonwebtoken';
import type { LevitySession } from 'next-auth';
import { useEffect } from 'react';
import userflow from 'userflow.js';

import { env } from '@/lib/env';

import { splitName } from '../../utils/text-transformations';
import { isE2ETestingUser } from '../helpers/email-validator.helper';
import { identify } from '../tracking';

export const useIdentifyUser = (ready: boolean, session: LevitySession | null) => {
  useEffect(() => {
    if (ready && session && session.user && session.user.levityUserId) {
      try {
        const userName = splitName(session.user.name ?? session.user.email);
        if (!isE2ETestingUser(userName.firstName)) {
          const decodedToken = decodeJwt(session.access_token);

          const emailVerifiedAt = dayjs((decodedToken as JwtPayload)?.emailVerified ?? dayjs().unix()).format(
            'YYYY-MM-DD HH:mm:ss',
          );

          identify({
            distinctId: session.user.levityWorkspaceId,
            email: session.user.email,
            userName: session.user.name ?? session.user.email,
            tenantId: session.user.levityWorkspaceId,
            signUpDate: emailVerifiedAt,
            userFirstName: userName.firstName,
            userLastName: userName.lastName,
          });

          userflow.init(env.NEXT_PUBLIC_USERFLOW_KEY);
          userflow.identify(session.user.levityUserId, {
            email: session.user.email,
            workspaceId: session.user.levityWorkspaceId,
            name: session.user.name,
            signed_up_at: emailVerifiedAt,
            profilePictureUrl: session.user.image,
          });
        }
      } catch (error) {
        console.log('Events not initialised', error);
      }
    }
  }, [session, ready]);
};
