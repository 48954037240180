import React from 'react';

import { IconProps } from '../icons.types';

export const LightningIcon: React.FC<IconProps> = (props) => (
  <svg
    width='17'
    height='23'
    viewBox='0 0 17 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='icon-lightning'
    {...props}>
    <g opacity='0.7'>
      <path
        opacity='0.5'
        d='M8.74532 17.9807C8.14762 18.857 6.77629 18.3222 6.92969 17.2726L7.24985 15.082C7.31171 14.6588 7.0975 14.243 6.71692 14.0477L4.15931 12.7353C3.62412 12.4606 3.45077 11.7791 3.78972 11.2821L8.06222 5.01791C8.65992 4.14157 10.0313 4.67638 9.87785 5.72599L9.55769 7.91653C9.49583 8.3398 9.71004 8.75554 10.0906 8.95084L12.6482 10.2633C13.1834 10.5379 13.3568 11.2195 13.0178 11.7165L8.74532 17.9807Z'
        fill='#B7B8ED'
      />
    </g>
  </svg>
);
