import Image from 'next/image';
import React from 'react';

import { Button, ButtonVariant } from '@/ui-library';
import { TestFlowStatus } from '@/utils/types/test-flow.types';

export const GmailCategorizeMessage = ({
  data,
  onCancel,
  onConfirm,
  status,
}: {
  data?: { categories: string[] };
  onCancel: () => void;
  onConfirm: () => void;
  status: TestFlowStatus;
}) => {
  const areAvailableLabels = data?.categories && data.categories.length > 0;
  if (status === TestFlowStatus.WARNING) {
    return (
      <>
        <h2 className='text-NeutralDarkDarkest text-base not-italic font-black mb-5'>
          Your flow executed properly, but...
        </h2>
        <div className='flex flex-col gap-2 justify-center mb-8'>
          <p className='text-NeutralDarkLight mb-3'>
            No labels or mail were found to classify. Try to add more labels in the AI Block and try again.
          </p>
          <div className='w-full flex gap-2 justify-center'>
            <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
              Got it
            </Button>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <h2 className='text-NeutralDarkDarkest text-base not-italic font-black mb-5'>
        {areAvailableLabels ? `We've labeled your last email as` : `We've categorised your last email, but...`}
      </h2>
      <div className='flex flex-row gap-2 justify-center mb-8'>
        {areAvailableLabels ? (
          data.categories.map((label, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`label-${index}`}
              className='bg-HighlightLightest text-black rounded-full p-5 h-10 flex items-center justify-center text-xs'>
              {label}
            </div>
          ))
        ) : (
          <p className='text-NeutralDarkLight mb-3'>
            No labels were found for it. Try to add more labels in the AI Block and try again.
          </p>
        )}
      </div>
      {areAvailableLabels ? <p className='text-NeutralDarkDark mb-3 text-sm'>Check the results in your inbox</p> : null}
      <div className='w-full flex gap-2 justify-center'>
        <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
          Close
        </Button>
        {areAvailableLabels ? (
          <Button variant={ButtonVariant.Primary} onClick={onConfirm}>
            <picture className='p-1 rounded-md bg-white'>
              <Image width={20} height={20} className='object-cover' src='/imgs/icons/gmail.svg' alt='Gmail Icon' />
            </picture>
            Open in Gmail
          </Button>
        ) : null}
      </div>
    </>
  );
};
