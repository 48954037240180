import { CopyTagTypes, OperationDefinition } from '../../../../flows/types';
import { CUSTOM, TAG } from '../../constants';

type OutlookOperationKeys =
  | 'GetAllMessages'
  | 'CreateDraft'
  | 'CategorizeListOfMessages'
  | 'GetLatestMessage'
  | 'ReplyToEmail'
  | 'CopyEmailToFolder'
  | 'ForwardEmail'
  | 'MoveEmailToFolder'
  | 'SendEmail';

export const outlookOperationsDefinition: Record<OutlookOperationKeys, OperationDefinition> = {
  GetAllMessages: {
    id: 'outlook-get-all-new-messages',
    name: 'Get All New Emails',
    description: 'New email received',
    icon: 'outlook',
    hasDynamicInputs: false,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'When email is received',
        tooltips: {
          description:
            'Specify an email Folder. If no selection is made, this Flow will trigger on all e-mails from the Inbox folder',
        },
      },
      actionDefinition: {
        elementTags: ['New email'],
        action: `When ${TAG} is received`,
      },
    },
  },
  CategorizeListOfMessages: {
    id: 'outlook-categorize-list-of-messages',
    name: 'Categorize list of messages',
    description: 'Categorize email',
    icon: 'outlook',
    hasDynamicInputs: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Update category',
        tooltips: {
          description: 'Choose a value to update the label in your incoming emails in Outlook.',
        },
      },
      interactiveParameters: {
        label: 'Categorize email label with',
        title: 'New email in Outlook',
        tooltips: {
          description: 'Choose a value to update the label of your incoming emails in Outlook.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['New email'],
        action: `Then update ${TAG} with ${CUSTOM}`,
      },
    },
  },
  CreateDraft: {
    id: 'outlook-create-draft',
    name: 'Create Draft Reply',
    description: 'Create a draft reply email',
    icon: 'outlook',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Create a draft reply email',
        tooltips: {
          description:
            'Write your draft email here. You can also include text generated from previous steps in this workflow.',
        },
      },
      interactiveParameters: {
        label: 'Draft email text',
        title: 'Draft email text in Outlook',
        tooltips: {
          description:
            'Write your draft email here. You can also include text generated from previous steps in this workflow.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Create a draft reply with ${CUSTOM}`,
      },
    },
  },
  GetLatestMessage: {
    id: 'outlook-get-latest-message',
    name: 'Get Latest Email',
    description: 'Latest email received',
    hasDynamicInputs: false,
    requiresAuth: true,
    copies: {
      actionDefinition: {
        elementTags: ['New email'],
        action: `When ${TAG} is received`,
      },
      operationContext: {
        title: '',
      },
    },
  },
  ReplyToEmail: {
    id: 'outlook-reply-to-email',
    name: 'Reply to new email',
    description: 'Reply to new email',
    hasDynamicInputs: false,
    requiresAuth: true,
    icon: 'outlook',
    copies: {
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['New email'],
        action: `Reply to new email with ${CUSTOM}`,
      },
      operationContext: {
        title: 'Reply to new email',
      },
      interactiveParameters: {
        label: 'Email body',
        title: 'Email reply to be sent',
        tooltips: {
          description:
            'Write your reply here. You can also include text generated from previous steps in this workflow.',
        },
      },
    },
  },
  MoveEmailToFolder: {
    id: 'outlook-move-email-to-folder',
    name: 'Move email to Folder',
    description: 'Move email to Folder',
    hasDynamicInputs: false,
    requiresAuth: true,
    hideDefaultDynamicInput: true,
    icon: 'outlook',
    copies: {
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['New email'],
        action: `Move ${TAG} to folder`,
      },
      operationContext: {
        title: 'Move email to Folder',
        tooltips: {
          description: 'The folder to which the email will be moved',
        },
      },
    },
  },
  CopyEmailToFolder: {
    id: 'outlook-copy-email-to-folder',
    name: 'Copy email to Folder',
    description: 'Copy email to Folder',
    hasDynamicInputs: false,
    requiresAuth: true,
    hideDefaultDynamicInput: true,
    icon: 'outlook',
    copies: {
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['New email'],
        action: `Copy ${TAG} to folder`,
      },
      operationContext: {
        title: 'Copy email to Folder',
        tooltips: {
          description: 'The folder to which the email will be copied',
        },
      },
    },
  },
  ForwardEmail: {
    id: 'outlook-forward-email',
    name: 'Forward email',
    description: 'Forward email',
    hasDynamicInputs: true,
    requiresAuth: true,
    hideDefaultDynamicInput: false,
    icon: 'outlook',
    copies: {
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['New email'],
        action: `Forward ${TAG}`,
      },
      operationContext: {
        title: 'Forward email',
      },
      interactiveParameters: {
        label: 'Recipients',
        title: 'Email addresses to which the email should be forwarded to',
        tooltips: {
          description: 'Email addresses should be comma separated',
        },
      },
    },
  },
  SendEmail: {
    id: 'outlook-send-email',
    name: 'Send new email',
    description: 'Send new email',
    icon: 'outlook',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Send new email',
        tooltips: {
          description:
            'Write your email here. You can also include text generated from previous steps in this workflow.',
        },
      },
      interactiveParameters: {
        label: 'Send new email text',
        title: 'Send new email text in Outlook',
        tooltips: {
          description:
            'Write your new email here. You can also include text generated from previous steps in this workflow.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Send a new email with ${CUSTOM}`,
      },
    },
  },
};
