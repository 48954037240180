import React, { useState } from 'react';

import { cn } from '@/utils/styles';

import { getGridCols } from './content-switch.core';
import type { ContentSwitchProps } from './content-switch.types';
import { ContentSwitchSize } from './content-switch.types';

export const ContentSwitch: React.FC<ContentSwitchProps> = (props) => {
  const {
    values,
    activeValue: activeControlledValue,
    defaultValue = values[0],
    onClick,
    bgColor = 'bg-NeutralLightLight',
    size = ContentSwitchSize.Default,
  } = props;

  // This internal compoennt state is only used if no activeValue is provided via props
  // So users have the choice to either control the state from the outside or automatically from the insight
  // This is e.g. useful, when clicking a value leads to redirect, which can lead to unmount/re-mount
  const [activeValue, setActiveValue] = useState(defaultValue);

  if (defaultValue && !values.includes(defaultValue)) {
    console.error('defaultValue must be a member of values');
  }

  const handleOnClick = (value: string) => {
    setActiveValue(value);
    if (onClick) onClick(value);
  };

  const gridCols = getGridCols(values.length);
  const sizeStyle = size === ContentSwitchSize.Small ? 'text-xs' : 'text-[13px]';

  return (
    <div
      className={cn(
        'font-semibold p-1 grid w-max cursor-pointer rounded-lg transition-colors duration-200 ease-in-out focus:outline-none',
        gridCols,
        bgColor,
        sizeStyle,
      )}>
      {values.map((value) => (
        <button
          key={value}
          type='button'
          onClick={() => handleOnClick(value)}
          className={cn(
            'py-2 px-4 rounded-lg hover:text-HighlightDark active:text-HighlightMedium',
            value === (activeControlledValue || activeValue)
              ? 'bg-white text-HighlightDarkest'
              : 'bg-transparent text-NeutralDarkLightest',
          )}>
          {value}
        </button>
      ))}
    </div>
  );
};
