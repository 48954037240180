export enum ContentSwitchSize {
  Default = 'default',
  Small = 'small',
}

export interface ContentSwitchProps {
  values: string[];
  activeValue?: string;
  defaultValue?: string;
  onClick?: (selectedOption: string) => void;
  bgColor?: string;
  size?: ContentSwitchSize;
}
