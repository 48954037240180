export const splitName = (fullName: string): { firstName: string; lastName: string } => {
  const nameParts = fullName.split(' ');
  if (nameParts.length === 1) return { firstName: fullName, lastName: '' };
  const firstName = nameParts.shift() || '';
  const lastName = nameParts.join(' ');
  return { firstName, lastName };
};

export const formatTimestampToISO8601 = (timestamp: string): string => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
};

export const normalizeString = (inputString: string): string => inputString.trim().replace(/\s+/g, '').toLowerCase();

export const capitalize = (text: string) => {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const trimAndCamelCase = (text: string) =>
  text
    .replace(/^./, (match) => match.toLowerCase())
    .replace(/(?:\s+|_+|-+)(\w)/g, (_, char) => char.toUpperCase())
    .replace(/\s+|_+|-+/g, '');

export const adjustTextareaRows = (text: string) => {
  const lineBreaks = (text.match(/\n/g) || []).length;
  const minRows = 3;
  const maxRows = 10;
  return Math.min(Math.max(lineBreaks + 1, minRows), maxRows) + 1;
};
