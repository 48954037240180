import React from 'react';

import { Button, ButtonVariant } from '../button';
import { Dialog } from '../dialog';
import type { ConfirmDialogProps } from './confirm-dialog.types';

export const ConfirmDialog = ({
  title,
  message,
  onClose,
  open,
  onConfirm,
  confirming,
  confirmButtonText = 'Confirm',
  confirmButtonVariant = ButtonVariant.Primary,
  cancelButtonText = 'No',
  info,
  secondaryConfirmButtonText,
  onSecondaryConfirm,
}: ConfirmDialogProps) => (
  <Dialog title={title} message={message} onClose={onClose} isOpen={open} info={info}>
    <div className='mt-8 flex items-center justify-center gap-3 self-center'>
      <Button className='w-full' onClick={onClose} text={cancelButtonText} variant={ButtonVariant.Secondary} />
      <Button
        className='w-full'
        text={confirming ? 'Loading...' : confirmButtonText}
        onClick={onConfirm}
        isLoading={confirming}
        variant={confirmButtonVariant}
      />
      {secondaryConfirmButtonText && (
        <Button
          className='w-full'
          text={confirming ? 'Loading...' : secondaryConfirmButtonText}
          onClick={onSecondaryConfirm}
          isLoading={confirming}
          variant={confirmButtonVariant}
        />
      )}
    </div>
  </Dialog>
);
