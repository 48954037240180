import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { cn } from '@/utils/styles';

import { speechBubblePositions, tipPositions } from './tooltip.core';
import type { TooltipContentProps, TooltipProps } from './tooltip.types';
import { TooltipPosition } from './tooltip.types';

const TooltipContent: React.FC<TooltipContentProps> = ({
  title,
  description,
  position,
  hideTip,
  tooltipPosition,
  usePortal,
}) => {
  return (
    <div
      {...(usePortal ? { style: { top: `${tooltipPosition.top}px`, left: `${tooltipPosition.left}px` } } : {})}
      className={cn(
        'absolute flex w-60 flex-col gap-2 rounded-lg bg-NeutralDarkDark p-5 text-xs leading-[14px] text-NeutralLightLightest transition-opacity duration-300 pointer-events-none',
        speechBubblePositions[position],
        usePortal
          ? 'tooltip z-100'
          : 'opacity-0 group-hover/tooltip:opacity-100 z-20 group-hover/tooltip:pointer-events-auto',
      )}>
      {title && <span className='font-bold'>{title}</span>}
      {description && <span className='font-normal'>{description}</span>}
      {!hideTip && <div className={`absolute h-3 w-3 bg-NeutralDarkDark ${tipPositions[position]}`} />}
    </div>
  );
};

export const Tooltip: React.FC<TooltipProps> = ({
  content,
  position = TooltipPosition.BottomLeft,
  hide = false,
  hideTip = false,
  children,
  // There are some small flaws when the position of the tooltip is on top, like the nodes in the flow canvas.
  // Using this prop allows us to pick which elements should be rendered in the portal or in the traditional way.
  usePortal = true,
  ...props
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const { title, description } = content || {};

  const updateTooltipPosition = () => {
    if (anchorRef.current) {
      const rect = anchorRef.current.getBoundingClientRect();

      setTooltipPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  };

  useEffect(() => {
    if (isVisible && usePortal) {
      updateTooltipPosition();
    }
  }, [isVisible, usePortal]);

  const handleMouseEnter = () => {
    if (!hide) {
      setIsVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <div
      ref={anchorRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className='relative inline-block group/tooltip'
      data-testid='tooltip'
      {...props}>
      {children}
      {!hide &&
        content &&
        isVisible &&
        (usePortal ? (
          ReactDOM.createPortal(
            <TooltipContent
              title={title}
              description={description}
              position={position}
              hideTip={hideTip}
              tooltipPosition={tooltipPosition}
              usePortal={usePortal}
            />,
            document.body,
          )
        ) : (
          <TooltipContent
            title={title}
            description={description}
            position={position}
            hideTip={hideTip}
            tooltipPosition={tooltipPosition}
            usePortal={usePortal}
          />
        ))}
    </div>
  );
};
