/* eslint-disable @next/next/no-img-element */
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import Avatar from 'react-avatar';

import { Menu } from '@/ui-library';
import { cn } from '@/utils/styles';

import type { ProfileDropdownProps } from '../types';

export const ProfileDropdown: React.FC<ProfileDropdownProps> = (props) => {
  const { user, userNavigation, isCompact } = props;
  const router = useRouter();

  return (
    <Menu
      options={userNavigation.map((item) => ({
        text: item.name,
        onClick: () => router.push(item.href),
        icon: item.icon,
      }))}
      menuItemsClassName={isCompact ? 'bottom-[56px]' : 'bottom-[72px] min-w-[207px]'}>
      <div
        className={cn(
          'transition-all ease-in-out py-4 flex items-center justify-between rounded-xl',
          !isCompact && 'w-[207px] grow outline-1 outline outline-NeutralLightDark bg-NeutralLightLightest px-3',
        )}>
        <div className='flex items-center gap-2.5'>
          {user.image ? (
            <Image className='h-[30px] w-[30px] rounded-full bg-gray-50' src={user.image} alt='User avatar image' />
          ) : (
            <Avatar name={user.name || user.email} round={true} size='30' />
          )}
          {!isCompact && (
            <span className='text-[13px] text-NeutralDarkMedium leading-4 font-medium truncate max-w-[120px]'>
              {user.name || user.email}
            </span>
          )}
        </div>
        {!isCompact && <ChevronUpDownIcon className='w-5 h-5 min-w-[20px] text-NeutralDarkLightest' />}
      </div>
    </Menu>
  );
};
