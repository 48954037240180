import Image from 'next/image';
import React from 'react';

import { Button, ButtonVariant } from '@/ui-library';

import type { TestFlowStatus } from '../../utils/types/test-flow.types';

export const YoutubeDeleteComment = ({
  onCancel,
  onConfirm,
}: {
  data?: string[];
  onCancel: () => void;
  onConfirm: () => void;
  status?: TestFlowStatus;
}) => (
  <>
    <h2 className='text-NeutralDarkDarkest text-lg not-italic font-black mb-5'>{`We've deleted some comments for you`}</h2>
    <p className='text-NeutralDarkLight mb-3'>Check it in your YouTube Studio space</p>
    <div className='w-full flex gap-2 justify-center'>
      <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
        Cancel
      </Button>
      <Button variant={ButtonVariant.Primary} onClick={onConfirm}>
        <picture className='p-1 rounded-md bg-white'>
          <Image width={20} height={20} className='object-cover' src='/imgs/icons/youtube.svg' alt='YouTube Icon' />
        </picture>
        Open YouTube Studio
      </Button>
    </div>
  </>
);
