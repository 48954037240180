import React from 'react';

import { cn } from '@/utils/styles';

import type { LabelProps } from './label.types';

export const Label: React.FC<LabelProps> = ({ htmlFor, className = '', children, ...props }) => {
  const baseStyle = 'font-semibold text-xs';

  const classNames = cn(baseStyle, className);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      htmlFor={htmlFor}
      className={classNames}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}>
      {children}
    </label>
  );
};
