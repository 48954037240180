/* eslint-disable no-param-reassign */
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const MESSAGE = 'Your changes have not been saved. Are you sure you want to leave this page?';

export const useConfirmOnLeave = (unsavedChanges: boolean): void => {
  const router = useRouter();

  useEffect(() => {
    const shouldByPassconfimation = () => !unsavedChanges;
    const handleWindowClose = (e: BeforeUnloadEvent) => {
      if (shouldByPassconfimation()) return;
      e.preventDefault();
      // eslint-disable-next-line consistent-return, no-return-assign
      return (e.returnValue = MESSAGE);
    };
    const handleBrowseAway = () => {
      if (shouldByPassconfimation()) return;
      // eslint-disable-next-line no-alert
      if (window.confirm(MESSAGE)) return;
      router.events.emit('routeChangeError');
      // eslint-disable-next-line no-throw-literal
      throw 'routeChange aborted by user.';
    };
    window.addEventListener('beforeunload', handleWindowClose);
    router.events.on('routeChangeStart', handleBrowseAway);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
      router.events.off('routeChangeStart', handleBrowseAway);
    };
  }, [unsavedChanges, router.events]);
};
