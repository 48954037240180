import React from 'react';

import { cn } from '@/utils/styles';

import type { SupportTextProps } from './support-text.types';

export const SupportText: React.FC<SupportTextProps> = ({ text, className, isError = false }) => {
  const baseStyle = 'text-[10px] leading-4';

  const classNames = cn(baseStyle, className, isError ? 'text-SupportErrorMedium' : 'text-NeutralDarkLightest');

  return <span className={classNames}>{text}</span>;
};
