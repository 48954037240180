import React from 'react';

import { IconProps } from '../icons.types';

export const ProhibitIcon: React.FC<IconProps> = (props) => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z'
      stroke='currentColor'
      strokeWidth='1.6'
      strokeMiterlimit='10'
    />
    <path
      d='M5.16602 5.16638L16.8333 16.8336'
      stroke='currentColor'
      strokeWidth='1.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
