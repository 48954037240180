import { useState } from 'react';

export interface UseDialogParams {
  initialOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export const useDialog = (params?: Partial<UseDialogParams>) => {
  const [isOpen, setIsOpen] = useState(params?.initialOpen ?? false);

  const open = () => {
    setIsOpen(true);

    if (params?.onOpen) {
      params?.onOpen();
    }
  };

  const close = () => {
    setIsOpen(false);

    if (params?.onClose) {
      params?.onClose();
    }
  };

  return { isOpen, open, close };
};
