import React from 'react';

import { IconProps } from '../icons.types';

export const BurgerMenuIcon: React.FC<IconProps> = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='24' height='24' rx='4' fill='#F8F9FE' />
    <path d='M20 7H5' stroke='currentColor' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M17 12H5' stroke='currentColor' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M17 17H5' stroke='currentColor' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
