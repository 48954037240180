import React, { useState } from 'react';

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
}

// TODO: Replace and remove this component everywhere.
// Duplicate component to Tooltip component in UI library.
export const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => setIsVisible(true);

  const hideTooltip = () => setIsVisible(false);

  return (
    <div className='relative inline-block'>
      <div onMouseMove={showTooltip} onMouseLeave={hideTooltip} className='cursor-pointer'>
        {children}
      </div>
      {isVisible && (
        <div
          className='
              absolute
              top-10
              z-10
              min-w-[200px]
              rounded-md
              bg-black
              px-4 py-2 text-sm text-white opacity-90 shadow-lg
            '>
          {content}
        </div>
      )}
    </div>
  );
};
