import React from 'react';

import { IconProps } from '../icons.types';

export const InfoIcon: React.FC<IconProps> = (props) => (
  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <mask id='mask0_154_7736' maskUnits='userSpaceOnUse' x='1' y='1' width='23' height='23'>
      <ellipse cx='12.7092' cy='12.5' rx='11.0002' ry='11' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_154_7736)'>
      <rect x='0.708984' y='0.5' width='24.0004' height='24' fill='#5557F6' />
    </g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.342 11.3132C10.342 10.7609 10.7897 10.3132 11.342 10.3132H12.7088C13.2611 10.3132 13.7088 10.7609 13.7088 11.3132V15.97H14.9147C15.467 15.97 15.9147 16.4177 15.9147 16.97C15.9147 17.5223 15.467 17.97 14.9147 17.97H10.5029C9.95064 17.97 9.50293 17.5223 9.50293 16.97C9.50293 16.4177 9.95064 15.97 10.5029 15.97H11.7088V12.3132H11.342C10.7897 12.3132 10.342 11.8655 10.342 11.3132Z'
      fill='white'
    />
    <path
      d='M13.9414 8.33845C13.9414 9.06111 13.3556 9.64694 12.6329 9.64694C11.9103 9.64694 11.3244 9.06111 11.3244 8.33845C11.3244 7.6158 11.9103 7.02997 12.6329 7.02997C13.3556 7.02997 13.9414 7.6158 13.9414 8.33845Z'
      fill='white'
    />
  </svg>
);
