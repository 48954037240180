import React from 'react';

import { cn } from '@/utils/styles';

import type { CollectionItemProps } from '../collection.types';

export const CollectionItem: React.FC<CollectionItemProps> = (props) => {
  const { children, className, ...rest } = props;

  return (
    <div
      className={cn(
        'group/list-item flex gap-4 cursor-pointer flex-row items-center text-[13px] text-NeutralDarkMedium font-medium leading-4 hover:text-HighlightDarkest justify-between rounded-2xl bg-HighlightLightest p-4 ring-HighlightDarkest hover:ring-1',
        className,
      )}
      data-testid='collection-item'
      {...rest}>
      {children}
    </div>
  );
};
