interface TagImageType {
  src: string;
  alt: string;
  width: number;
  height: number;
}

export enum TagVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface TagProps {
  text: string;
  image?: TagImageType;
  variant?: TagVariant;
}
