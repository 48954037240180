import { Menu as HeadlessMenu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

import { cn } from '@/utils/styles';

import type { MenuProps } from './menu.types';
import { MenuPosition } from './menu.types';

export const Menu: React.FC<MenuProps> = (props) => {
  const { position = MenuPosition.Left, options, onClick, children, menuItemsClassName } = props;

  // We need to preventDefault to prevent re-routing on Link components when toggling the menu
  // Tried stopPropagation as well, but didn't work. Root cause could not be identified.
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, open: boolean, close: () => void) => {
    if (onClick) onClick(open);
    if (open) {
      e.preventDefault();
      close();
    }
  };

  return (
    <div className='flex items-center w-fit'>
      <HeadlessMenu as='div' className='relative inline-block text-left'>
        {({ open, close }) => (
          <>
            <HeadlessMenu.Button
              onClick={(e) => handleClick(e, open, close)}
              className='flex items-center hover:fill-HighlightDarkest'
              data-testid='menu-button'>
              {children}
            </HeadlessMenu.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'>
              <HeadlessMenu.Items
                className={cn(
                  position === MenuPosition.Right ? 'right-0' : 'left-0',
                  menuItemsClassName,
                  'absolute mt-2 origin-top-right rounded-[10px] bg-white z-50 shadow-menu-options ring-1 ring-NeutralLightMedium focus:outline-none',
                )}>
                <div className='flex flex-col px-1.5 py-3.5 gap-2.5 '>
                  {options.map((option) => (
                    <HeadlessMenu.Item key={option.text}>
                      {({ active }) => (
                        <button
                          onClick={option.onClick}
                          className={`${
                            active
                              ? 'text-HighlightDark'
                              : 'text-NeutralDarkLight hover:text-HighlightDark active:text-HighlightDark'
                          } group whitespace-nowrap flex gap-3 items-center rounded-md px-4 py-2 font-medium text-[13px]`}>
                          {option.icon}
                          {option.text}
                        </button>
                      )}
                    </HeadlessMenu.Item>
                  ))}
                </div>
              </HeadlessMenu.Items>
            </Transition>
          </>
        )}
      </HeadlessMenu>
    </div>
  );
};
