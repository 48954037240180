import { Editor } from '@monaco-editor/react';
import React from 'react';

import { cn } from '@/utils/styles';

import { Label, SupportText } from '../form/components';
import { QuestionIcon, Tooltip } from '../tooltip';
import { CodeEditorProps } from './code-editor.types';

export const CodeEditor = (props: CodeEditorProps) => {
  const {
    name = 'code-editor',
    label = 'Code',
    tooltip,
    supportText,
    isOptional = false,
    isError = false,
    className,
    readOnly = false,
    editorProps,
  } = props;

  return (
    <div className='flex flex-col gap-2'>
      {label && (
        <div className='flex items-center gap-1 '>
          <Label htmlFor={name}>
            <div className='flex items-center gap-1'>
              {label}
              {isOptional && <span className='font-normal text-NeutralDarkLightest'>Optional</span>}
            </div>
          </Label>
          {tooltip && (
            <Tooltip content={tooltip}>
              <QuestionIcon />
            </Tooltip>
          )}
        </div>
      )}
      <Editor
        className={cn(
          'min-h-[128px] h-full rounded-lg overflow-hidden pt-4 bg-white border border-NeutralLightDarkest',
          className,
        )}
        defaultLanguage='javascript'
        options={{
          name,
          minimap: { enabled: false },
          lineNumbers: 'on',
          lineNumbersMinChars: 3,
          readOnly,
        }}
        {...editorProps}
      />
      {supportText ? <SupportText text={supportText} isError={isError} /> : null}
    </div>
  );
};
