import { CopyTagTypes, OperationDefinition } from '../../../../flows/types';
import { CUSTOM, TAG } from '../../constants';

type ZendeskOperationKeys =
  | 'UpdateTicketTags'
  | 'GetTicket'
  | 'GetTickets'
  | 'UpdateTicketStatus'
  | 'AssignTicket'
  | 'CommentOnTicket';

export const zendeskOperationsDefinition: Record<ZendeskOperationKeys, OperationDefinition> = {
  UpdateTicketTags: {
    id: 'zendesk-update-tags',
    name: 'Update Tags',
    description: 'Update ticket tags',
    icon: 'zendesk',
    hasDynamicInputs: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Update tag',
        tooltips: {
          description: 'Choose a value to update the tag of your incoming ticket in Zendesk.',
        },
      },
      interactiveParameters: {
        label: 'Tag name',
        title: 'Tag name',
        tooltips: {
          description: 'Choose a value to update the tag of your incoming ticket in Zendesk.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Update ticket tag  with ${CUSTOM}`,
      },
    },
  },
  GetTicket: {
    id: 'zendesk-get-ticket',
    name: 'Get Ticket',
    description: 'get specific Zendesk ticket',
    icon: 'zendesk',
    hasDynamicInputs: false,
    requiresAuth: true,
  },
  GetTickets: {
    id: 'zendesk-get-tickets',
    name: 'Get Tickets',
    description: 'New ticket received',
    icon: 'zendesk',
    hasDynamicInputs: false,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'When ticket is received',
      },
      actionDefinition: {
        elementTags: ['New ticket'],
        action: `When ${TAG} is received`,
      },
    },
  },
  UpdateTicketStatus: {
    id: 'zendesk-update-status',
    name: 'Update Ticket Status',
    description: 'Update ticket status',
    icon: 'zendesk',
    hasDynamicInputs: false,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Update ticket status',
        tooltips: {
          description: 'Choose a value to update the status of your incoming ticket in Zendesk.',
        },
      },
      interactiveParameters: {
        label: 'Status',
        title: 'Status',
        tooltips: {
          description: 'Choose a value to update the status of your incoming ticket in Zendesk.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Update ticket status  with ${CUSTOM}`,
      },
    },
  },
  AssignTicket: {
    id: 'zendesk-assign-ticket',
    name: 'Assign Ticket',
    description: 'Assign ticket',
    icon: 'zendesk',
    hasDynamicInputs: false,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Assign ticket',
        tooltips: {
          description: 'Choose a user to assign to your incoming ticket in Zendesk.',
        },
      },
      interactiveParameters: {
        label: 'Assignee',
        title: 'Assignee',
        tooltips: {
          description: 'Choose a user to assign to your incoming ticket in Zendesk.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Update ticket with assignee`,
      },
    },
  },
  CommentOnTicket: {
    id: 'zendesk-comment-ticket',
    name: 'Comment on Ticket',
    description: 'Comment on ticket',
    icon: 'zendesk',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: false,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Comment on ticket',
        tooltips: {
          description: 'Choose the user who should send the comment',
        },
      },
      interactiveParameters: {
        label: 'Comment',
        title: 'Comment on ticket',
        tooltips: {
          description:
            'Write your comment here. You can also include text generated from previous steps in this workflow.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Create a comment with ${CUSTOM}`,
      },
    },
  },
};
