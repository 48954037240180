import { useEffect, useState } from 'react';

export const useRefreshAiBlock = (aiBlockId: string | undefined = undefined) => {
  const [currentAiBlockId, setCurrentAiBlockId] = useState(aiBlockId);

  useEffect(() => {
    setCurrentAiBlockId(aiBlockId);
  }, [aiBlockId]);

  return currentAiBlockId !== aiBlockId;
};
