import { Dialog as HeadlessUIDialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

import { cn } from '../../utils/styles';
import type { DialogProps } from './dialog.types';

export const Dialog = ({
  isOpen,
  onClose,
  title,
  message,
  children,
  info,
  dissablePadding = false,
  width = 'max-w-md min-w-[528px]',
}: DialogProps) => (
  <Transition appear show={isOpen} as={Fragment}>
    <HeadlessUIDialog as='div' className='relative z-[9999]' onClose={onClose} open={isOpen}>
      <Transition.Child
        as={Fragment}
        enter='ease-out duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'>
        <div className='fixed inset-0 bg-black bg-opacity-25' />
      </Transition.Child>
      <div className='fixed inset-0 overflow-y-auto' data-testid='dialog'>
        <div className='flex min-h-full items-center justify-center p-4 text-center'>
          <HeadlessUIDialog.Panel
            className={cn(
              `flex w-full transform flex-col overflow-hidden rounded-2xl align-middle shadow-xl transition-all bg-white`,
              width,
            )}>
            <div className='px-12 pt-12'>
              {title ? (
                <HeadlessUIDialog.Title as='h3' className='text-center font-bold leading-6 text-NeutralDarkDarkest'>
                  {title}
                </HeadlessUIDialog.Title>
              ) : null}

              {message ? (
                <div className='mt-2'>
                  <p className='text-center text-sm text-NeutralDarkUltraLight'>{message}</p>
                </div>
              ) : null}

              {info ? (
                <div className='mt-2'>
                  <p className='text-center text-sm text-NeutralDarkLight'>{info}</p>
                </div>
              ) : null}
            </div>

            <div className={cn(dissablePadding ? 'p-0' : 'px-12 pb-12')}>{children}</div>
          </HeadlessUIDialog.Panel>
        </div>
      </div>
    </HeadlessUIDialog>
  </Transition>
);
