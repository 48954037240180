import { SwitchSize } from './switch.types';

export const getSwitchSize = (size: SwitchSize) => {
  switch (size) {
    case SwitchSize.SMALL:
      return 'h-4 w-8';
    case SwitchSize.MEDIUM:
      return 'h-5 w-10';
    case SwitchSize.LARGE:
      return 'h-[28px] w-[45px]';
    default:
      return 'h-5 w-10';
  }
};

export const getSwitchKnobSize = (size: SwitchSize) => {
  switch (size) {
    case SwitchSize.SMALL:
      return 'h-3 w-3';
    case SwitchSize.MEDIUM:
      return 'h-4 w-4';
    case SwitchSize.LARGE:
      return 'h-5 w-5';
    default:
      return 'h-4 w-4';
  }
};

export const getSwitchKnobTranslateX = (checked: boolean, size: SwitchSize) => {
  if (!checked) return 'translate-x-0';
  switch (size) {
    case SwitchSize.SMALL:
      return 'translate-x-4';
    case SwitchSize.MEDIUM:
      return 'translate-x-5';
    case SwitchSize.LARGE:
      return 'translate-x-[21px]';
    default:
      return 'translate-x-5';
  }
};
