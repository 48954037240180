import React from 'react';

import { Button, ButtonVariant } from '@/ui-library';

import { TestFlowStatus } from '../../../utils/types/test-flow.types';

export const ZendeskCommentOnTicket = ({
  onCancel,
}: {
  data?: { categories: string[] };
  onCancel: () => void;
  onConfirm: () => void;
  status: TestFlowStatus;
}) => {
  return (
    <>
      <h2 className='text-NeutralDarkDarkest text-lg not-italic font-black mb-5'>{`We've sent the comment for you`}</h2>
      <p className='text-NeutralDarkLight mb-3'>Check your ticket in Zendesk to see the comment</p>
      <div className='w-full flex gap-2 justify-center'>
        <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
          Close
        </Button>
      </div>
    </>
  );
};
