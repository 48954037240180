import React from 'react';

import { IconProps } from '../icons.types';

export const LightningOutlineIcon: React.FC<IconProps> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <path
      d='M6 14.999L7 9.99902L3 8.49902L10 0.999023L9 5.99902L13 7.49902L6 14.999Z'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
