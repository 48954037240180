import { CopyTagTypes, OperationDefinition } from '../../../../flows/types';
import { CUSTOM, TAG } from '../../constants';

type FrontOperationKeys =
  | 'NewConversation'
  | 'NewMessage'
  | 'AddConversationTags'
  | 'GenerateDraftReply'
  | 'UpdateConversationAssignee'
  | 'UpdateConversationCustomFields'
  | 'ReplyToMessage';

export const frontOperationsDefinition: Record<FrontOperationKeys, OperationDefinition> = {
  NewConversation: {
    id: 'front-new-conversation',
    name: 'New conversation',
    description: 'New conversation is started',
    icon: 'front',
    hasDynamicInputs: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'When new conversation is started',
      },
      actionDefinition: {
        elementTags: ['New conversation'],
        action: `When ${TAG} is started`,
      },
      interactiveParameters: {
        label: 'Inboxes',
        title: 'Inboxes',
        tooltips: {
          description: 'Choose your company inboxes where you want to look for new conversations.',
        },
      },
    },
  },
  NewMessage: {
    id: 'front-new-message',
    name: 'New message',
    description: 'New message received',
    icon: 'front',
    hasDynamicInputs: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'When new message is received',
      },
      actionDefinition: {
        elementTags: ['New message'],
        action: `When ${TAG} is received`,
      },
      interactiveParameters: {
        label: 'Inboxes',
        title: 'Inboxes',
        tooltips: {
          description: 'Choose your company inboxes where you want to look for new messages.',
        },
      },
    },
  },
  AddConversationTags: {
    id: 'front-add-conversation-tags',
    name: 'Add conversation tags',
    description: 'Tag conversation',
    icon: 'front',
    hasDynamicInputs: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Tag conversation',
        tooltips: {
          description: 'Choose a value to tag the conversation in your new conversation in Front.',
        },
      },
      interactiveParameters: {
        label: 'Tag conversation with',
        title: 'New conversation in Front',
        tooltips: {
          description: 'Choose a value to tag the conversation in your new conversation in Front.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['New conversation'],
        action: `Then update ${TAG} with ${CUSTOM}`,
      },
    },
  },
  GenerateDraftReply: {
    id: 'front-draft-reply',
    name: 'Generate draft reply',
    description: 'Generate a draft reply',
    icon: 'front',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Generate draft reply',
        tooltips: {
          description: 'Choose a value to tag the conversation in your new conversation in Front.',
        },
      },
      interactiveParameters: {
        label: 'Body',
        title: 'New conversation in Front',
        tooltips: {
          description: 'Choose a value to tag the conversation in your new conversation in Front.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['Draft reply'],
        action: `Then generate ${TAG} with ${CUSTOM}`,
      },
    },
  },
  UpdateConversationAssignee: {
    id: 'front-update-conversation-assignee',
    name: 'Update conversation assignee',
    description: 'Update conversation assignee',
    icon: 'front',
    hasDynamicInputs: false,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Update conversation assignee',
        tooltips: {
          description: 'Choose an assignee to update the conversation.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['New conversation'],
        action: `Then update ${TAG} with assignee`,
      },
    },
  },
  UpdateConversationCustomFields: {
    id: 'front-update-conversation-custom-fields',
    name: 'Update conversation custom fields of type text',
    description: 'Update conversation custom fields of type text',
    icon: 'front',
    hasDynamicInputs: false,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Update conversation custom fields of type text',
        tooltips: {
          description: 'Select conversation custom fields',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['New conversation'],
        action: `Then update ${TAG} custom fields`,
      },
    },
  },
  ReplyToMessage: {
    id: 'front-reply-to-message',
    name: 'Reply to new message',
    description: 'Reply to new message',
    hasDynamicInputs: false,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    icon: 'front',
    copies: {
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['New message'],
        action: `Reply to new message with ${CUSTOM}`,
      },
      operationContext: {
        title: 'Reply to new message',
      },
      interactiveParameters: {
        label: 'Message body',
        title: 'Reply to be sent',
        tooltips: {
          description:
            'Write your reply here. You can also include text generated from previous steps in this workflow.',
        },
      },
    },
  },
};
