import React from 'react';
import { useFormContext } from 'react-hook-form';

import { cn } from '@/utils/styles';

import { QuestionIcon, Tooltip } from '../../../tooltip';
import { Label } from '../label';
import { SupportText } from '../support-text';
import type { TextAreaProps, TextAreaRawProps } from './text-area.types';

const TextAreaRaw: React.FC<TextAreaRawProps> = ({
  name,
  placeholder,
  isError = false,
  disabled = false,
  rows = 4,
  className = '',
  ...props
}) => {
  const { register, watch } = useFormContext();

  const value = watch(name);

  // Styles
  const baseStyle =
    'block w-full resize-y rounded-md border-0 px-4 py-3.5 text-NeutralDarkDarkest shadow-sm placeholder:text-NeutralDarkLightest outline-none text-sm sm:leading-6 disabled:bg-NeutralLightLight disabled:text-NeutralLightDarkest';

  const ringStyles = isError
    ? 'ring-inset ring-SupportErrorMedium ring-[1.5px]'
    : 'ring-inset ring-1 ring-NeutralLightDarkest hover:ring-HighlightMedium hover:ring-[1.5px] focus:ring-HighlightMedium focus:ring-[1.5px] disabled:ring-NeutralLightDarkest disabled:ring-1';

  const classNames = cn(baseStyle, ringStyles, className);

  return (
    <textarea
      id={name}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      className={classNames}
      rows={rows}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...register(name)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export const TextArea: React.FC<TextAreaProps> = ({
  name,
  label,
  tooltip,
  supportText,
  placeholder,
  disabled,
  rows,
  isOptional,
  labelAction,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message as string;

  const isError = Boolean(errorMessage);

  return (
    <div className='flex flex-col gap-2'>
      {label && (
        <div className='flex items-center gap-1'>
          <Label htmlFor={name}>
            {label}
            {isOptional && <span className='ml-1 font-normal text-NeutralDarkLightest'>Optional</span>}
            {labelAction && (
              <button onClick={labelAction.onAction} className='ml-1 font-medium text-HighlightDarkest'>
                {labelAction.text}
              </button>
            )}
          </Label>
          {tooltip && (
            <Tooltip content={tooltip}>
              <QuestionIcon />
            </Tooltip>
          )}
        </div>
      )}
      <TextAreaRaw
        name={name}
        rows={rows}
        placeholder={placeholder}
        disabled={disabled}
        isError={isError}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {supportText || errorMessage ? <SupportText text={errorMessage ?? supportText} isError={isError} /> : null}
    </div>
  );
};
