import { normalizeString } from './text-transformations';

interface Block {
  name: string;
  emoji: string;
  labels?: string[];
  initialTestInput: string;
  initialContext?: string;
}

export type BlockKeys = 'classify' | 'generate' | 'extract' | 'summary' | 'custom';

// TODO: Remove initial values added for testing
export const SAMPLE_BLOCKS: Map<BlockKeys, Block> = new Map([
  [
    'classify',
    {
      name: '[TEMPLATE] Categorize incoming emails from shippers',
      emoji: '📨',
      labels: [
        'Quote request',
        'Order confirmation',
        'Change to order',
        'Track & Trace',
        'Documentation request',
        'Complaint',
        'Other',
      ],
      initialTestInput: `Hi Super Fast Logistics,\n\nPlease provide your current all-in rate for a truckload shipment from Chicago to Dallas?\n\nThanks,\nShipper McShipface`,
    },
  ],
  [
    'extract',
    {
      name: '[TEMPLATE] Extract load details from emails',
      emoji: '🔍',
      labels: ['Pickup City', 'Pickup Date', 'Delivery City', 'Delivery Date', 'Commodity', 'Weight', 'Equipment'],
      initialTestInput: `Hi Super Fast Logistics,\n\nWe've got a load that needs to go from Chicago, IL to Dallas, TX. PU March 15, DEL March 16. About 20,000 lbs of electronics, we need a 53' dry van.\n\nPlease respond with your all-in rate.\n\nThanks,\nShipper McShipface`,
    },
  ],
]);

export const getSampleBlock = (aiBlockName: string): Block | undefined =>
  Array.from(SAMPLE_BLOCKS.values()).find((value) => normalizeString(value.name) === normalizeString(aiBlockName));
