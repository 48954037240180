import type { TooltipContent } from '../../../tooltip';
import type { LabelAction } from '../label';

export enum ComboboxHeight {
  Small = 'small',
  Medium = 'medium',
}

export interface ComboBoxOption {
  id: string;
  label: string;
}

export interface ComboBoxRawProps extends Omit<React.HTMLProps<HTMLSelectElement>, 'onChange'> {
  name: string;
  options: ComboBoxOption[];
  isError?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export interface ComboBoxProps extends ComboBoxRawProps {
  label?: string;
  tooltip?: TooltipContent;
  supportText?: string;
  isOptional?: boolean;
  labelAction?: LabelAction;
  height?: ComboboxHeight;
  onChange?: (value: string) => void;
}
