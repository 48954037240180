import type React from 'react';

export enum MenuPosition {
  Left = 'left',
  Right = 'right',
}

export interface MenuOption {
  text: string;
  icon?: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export interface MenuProps {
  children: React.ReactElement;
  options: MenuOption[];
  onClick?: (open: boolean) => void;
  position?: MenuPosition;
  menuItemsClassName?: string;
}
