import { Dialog } from '@headlessui/react';
import { ClipboardIcon } from '@heroicons/react/20/solid';
import { type AiBlock } from '@prisma/client';
import React, { useEffect, useRef, useState } from 'react';

import { Layout, QuestionIcon } from '@/ui-library';

import { Divider } from '../divider.component';
import { Tooltip } from '../tooltip.component';

interface CodeBlockProps {
  code: string;
  language?: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ code, language = 'javascript' }) => {
  const codeRef = useRef<HTMLPreElement>(null);
  const [copied, setCopied] = useState(false);

  const showCopiedMessage = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const copyToClipboard = () => {
    const range = document.createRange();
    const selection = window.getSelection();
    if (codeRef.current && selection) {
      range.selectNodeContents(codeRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();
      showCopiedMessage();
    }
  };

  const lines = code.split('\n').map((line, idx) => ({ lineId: idx + 1, line }));
  const isMultiline = lines.length > 1;

  return (
    <div className='relative'>
      <div className='flex w-full justify-end rounded-t-md bg-slate-700 p-2'>
        <button
          type='button'
          className='right-2 top-2 rounded-md bg-transparent p-1 hover:bg-slate-600'
          onClick={copyToClipboard}>
          <ClipboardIcon title='Copy' className='h-4 w-4 text-slate-300' />
        </button>
        {copied && (
          <span
            className='absolute right-10 top-2 z-10 rounded-md bg-green-500 px-2 py-1 text-xs text-white transition-all duration-300'
            style={{ opacity: copied ? 100 : 0 }}>
            Copied!
          </span>
        )}
      </div>
      <pre
        ref={codeRef}
        className={`overflow-auto rounded-b-md bg-slate-800 p-4 pl-8 pr-10 ${
          isMultiline ? '' : 'pr-16'
        } font-mono text-sm text-white ${language ? `language-${language}` : ''}`}
        style={{ position: 'relative' }}>
        <code>
          {lines.map(({ lineId, line }) => (
            <div key={lineId}>
              <span className='absolute left-4 select-none text-gray-500'>{lineId}</span>
              {line}
              <br />
            </div>
          ))}
        </code>
      </pre>
    </div>
  );
};
export default CodeBlock;

interface ApiKeyProps {
  token: string | null;
}

const ApiKey: React.FC<ApiKeyProps> = ({ token }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (token) {
      navigator.clipboard.writeText(token);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };

  if (!token) {
    return <div>No token provided</div>;
  }

  return (
    <div>
      <div className='relative inline-block text-sm'>
        <div className='flex'>
          <span className='inline w-[230px] select-all overflow-hidden text-ellipsis whitespace-nowrap'>{token}</span>
          <button
            type='button'
            onClick={handleCopy}
            className='ml-2 rounded p-1 text-sm text-slate-300 hover:bg-slate-100 focus:outline-none '>
            <ClipboardIcon title='Copy' className='h-4 w-4' />
          </button>
          {copied && (
            <span
              className='absolute top-5 z-10 rounded-md bg-green-500 p-1 text-xs text-white transition-all duration-300'
              style={{ opacity: copied ? 100 : 0 }}>
              Copied!
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const getAiBlockConfig = (hostUrl: string, aiBlock: AiBlock, token: string | null) => ({
  classify: {
    // FIX: the output doesn't match the example below
    step1: `${hostUrl}/api/ai/v3/${aiBlock.id}/classify`,
    step2: `curl ${hostUrl}/api/ai/v3/${aiBlock.id}/classify \\
  -H "Authorization: Bearer ${token}" \\
  -H "Content-Type: application/json" \\
  -d '{ "textToClassify": "This is the text I want to classify." }'`,
    step3: `{
  "labels": [
    "string"
  ],
  "text": "string"
}`,
    bodyLine: [
      'This endpoint expects a JSON in the request body (or payload body). It must be an object with the ',
      <strong key='classification-body-prop-1'>text</strong>,
      ' property. For example:',
    ],
    responseLine: [
      'The response body is JSON formatted. A successful classification will return a list of labels for the given text in the ',
      <strong key='response-operation'>label</strong>,
      ' parameter.',
    ],
  },
  extract: {
    step1: `${hostUrl}/api/ai/v2/${aiBlock.id}/extract`,
    step2: `curl ${hostUrl}/api/ai/v2/${aiBlock.id}/extract \\
  -H "Authorization: Bearer ${token}" \\
  -H "Content-Type: application/json" \\
  -d '{ "text":"This is the text I want to extract."}'`,
    step3: `{
  "extracted": {
    "extracted_key_1": string | null,
    "extracted_key_2": string | null
  }
}`,
    bodyLine: [
      'This endpoint expects a JSON in the request body (or payload body). It must be an object with the ',
      <strong key='classification-body-prop-1'>text</strong>,
      ' property. For example:',
    ],
    responseLine: [
      'The response body is JSON formatted. A successful extraction will return a property named ',
      <strong key='response-operation'>extracted</strong>,
      ' with the extracted information.',
    ],
  },
  generate: {
    step1: `${hostUrl}/api/ai/v2/${aiBlock.id}/generate`,
    step2: `curl ${hostUrl}/api/ai/v2/${aiBlock.id}/generate \\
  -H "Authorization: Bearer ${token}" \\
  -H "Content-Type: application/json" \\
  -d '{ "text":"Example description to generate.", "context":"Some context for the generation"}'`,
    step3: `{
  "generated": "string"
}`,
    bodyLine: [
      'This endpoint expects a JSON in the request body (or payload body). It must be an object with the ',
      <strong key='classification-body-prop-1'>text</strong>,
      ' and ',
      <strong key='classification-body-prop-2'>context</strong>,
      ' properties. For example:',
    ],
    responseLine: [
      'The response body is JSON formatted. A successful generation will return a property named ',
      <strong key='response-operation'>generated</strong>,
      ' with the generated text.',
    ],
  },
  summarize: {
    step1: `${hostUrl}/api/ai/v2/${aiBlock.id}/summarize`,
    step2: `curl ${hostUrl}/api/ai/v2/${aiBlock.id}/summarize \\
  -H "Authorization: Bearer ${token}" \\
  -H "Content-Type: application/json" \\
  -d '{ "text":"Text to summarize.", "context":"Some context for the summarisation"}'`,
    step3: `{
  "summary": "string"
}`,
    bodyLine: [
      'This endpoint expects a JSON in the request body (or payload body). It must be an object with the ',
      <strong key='classification-body-prop-1'>text</strong>,
      ' and ',
      <strong key='classification-body-prop-2'>context</strong>,
      ' properties. For example:',
    ],
    responseLine: [
      'The response body is JSON formatted. A successful summarisation will return a property named ',
      <strong key='response-operation'>summary</strong>,
      ' with the summarised text.',
    ],
  },
  // TODO: Add custom AI block configuration in follow up PR
  custom: {
    step1: `${hostUrl}/api/ai/v2/${aiBlock.id}/generate`,
    step2: `curl ${hostUrl}/api/ai/v2/${aiBlock.id}/generate \\
  -H "Authorization: Bearer ${token}" \\
  -H "Content-Type: application/json" \\
  -d '{ "text":"Example description to generate.", "context":"Some context for the generation"}'`,
    step3: `{
  "generated": "string"
}`,
    bodyLine: [
      'This endpoint expects a JSON in the request body (or payload body). It must be an object with the ',
      <strong key='classification-body-prop-1'>text</strong>,
      ' and ',
      <strong key='classification-body-prop-2'>context</strong>,
      ' properties. For example:',
    ],
    responseLine: [
      'The response body is JSON formatted. A successful generation will return a property named ',
      <strong key='response-operation'>generated</strong>,
      ' with the generated text.',
    ],
  },
});

export const ConnectToApiModal = ({
  isOpen,
  onClose,
  aiBlock,
  token,
}: {
  isOpen: boolean;
  onClose: () => void;
  aiBlock: AiBlock;
  token: string | null;
}) => {
  const [hostUrl, setHostUrl] = useState('');

  useEffect(() => {
    const currentHostUrl = window.location.origin;
    setHostUrl(currentHostUrl);
  }, []);

  const currentConfig = getAiBlockConfig(hostUrl, aiBlock, token)[aiBlock.type];

  return (
    <Dialog open={isOpen} onClose={onClose} className='relative z-50'>
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className='fixed inset-0 bg-black/30' aria-hidden='true' />

      {/* Full-screen container to center the panel */}
      <div className='fixed inset-0 flex items-center justify-center p-4'>
        {/* The actual dialog panel  */}
        <Dialog.Panel className='max-h-[720px] max-w-2xl overflow-scroll rounded-xl bg-white p-8'>
          <h1 className='w-full justify-center text-base font-semibold'>Connect to API</h1>
          <div className='flex w-full flex-col justify-start gap-4 py-5 sm:flex-row sm:items-center sm:gap-8'>
            <div className='flex flex-col'>
              <h4 className='text-sm font-semibold'>AI Block ID</h4>
              <p className='text-sm'>{aiBlock.id}</p>
            </div>
            <div className='flex flex-col'>
              <h4 className='flex items-center text-sm font-semibold'>
                <span className='mr-1'>Your API Key</span>
                <Tooltip content='Bearer token to be used in the Authorization header of the requests'>
                  <QuestionIcon />
                </Tooltip>
              </h4>
              <ApiKey token={token} />
            </div>
          </div>
          <Divider />
          <Layout.Spacer bottom={5} />
          <div className='flex flex-col gap-5'>
            <p className='text-sm'>
              To <strong>{aiBlock.type}</strong> make a POST request to the following URL:
            </p>
            <CodeBlock code={currentConfig.step1} language='typescript' />
            <p className='text-sm'>{currentConfig.bodyLine}</p>
            <CodeBlock code={currentConfig.step2} language='typescript' />
            <p className='text-sm'>{currentConfig.responseLine}</p>
            <p className='text-sm'>For example for the previous request the response body will look like this:</p>
            <CodeBlock code={currentConfig.step3} language='typescript' />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
