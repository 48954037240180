import { useIdentifyUser } from './hooks/useIdentifyUser';
import { getAvailableSurveyByName, getAvailableSurveys } from './surveys';
import {
  identify,
  trackCustomEvent,
  trackInputChanged,
  trackItemClicked,
  trackModalClosed,
  trackModalOpened,
} from './tracking';

export {
  getAvailableSurveyByName,
  getAvailableSurveys,
  identify,
  trackCustomEvent,
  trackInputChanged,
  trackItemClicked,
  trackModalClosed,
  trackModalOpened,
  useIdentifyUser,
};
