import type { TooltipContent } from '../../../tooltip';
import type { LabelAction } from '../label';

export enum MultiSelectDropdownHeight {
  Small = 'small',
  Medium = 'medium',
}

export interface MultiSelectDropdownOption {
  id: string;
  label: string;
}

export interface MultiSelectDropdownRawProps extends Omit<React.HTMLProps<HTMLSelectElement>, 'onChange'> {
  name: string;
  options: MultiSelectDropdownOption[];
  isError?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export interface MultiSelectDropdownProps extends MultiSelectDropdownRawProps {
  label?: string;
  tooltip?: TooltipContent;
  supportText?: string;
  isOptional?: boolean;
  labelAction?: LabelAction;
  height?: MultiSelectDropdownHeight;
  onChange?: (value: MultiSelectDropdownOption[]) => void;
}
