import { closeSnackbar, enqueueSnackbar } from 'notistack';

import type { ToastProps } from './toast.types';

export const useToast = () => {
  const showToast = (settings: ToastProps) => {
    enqueueSnackbar({
      variant: 'customToast',
      title: settings.title,
      description: settings.description,
      type: settings.type,
      anchorOrigin: settings.anchorOrigin,
    });
  };

  return { showToast, closeToast: closeSnackbar };
};
