import React from 'react';

import type { PageProps } from '../layout.types';

export const Page: React.FC<PageProps> = (props) => {
  const { children } = props;

  return (
    <div className='font-sans antialiased flex flex-col justify-center items-center m-auto h-full w-full bg-NeutralLightLight text-NeutralDarkDarkest'>
      {children}
    </div>
  );
};
