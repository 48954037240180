export const speechBubblePositions = {
  topLeft: 'bottom-full mb-4 ml-[-18px]',
  topCenter: 'bottom-full mb-4 ml-[-112px]',
  topRight: 'bottom-full mb-4 ml-[-202px]',
  bottomLeft: 'top-full mt-4 ml-[-18px]',
  bottomCenter: 'top-full mt-4 ml-[-112px]',
  bottomRight: 'top-full mt-4 ml-[-202px]',
};

export const tipPositions = {
  topLeft: 'bottom-0 mb-[-6px] transform rotate-45',
  topCenter: 'bottom-0 mb-[-6px] ml-[94px] transform rotate-45',
  topRight: 'bottom-0 mb-[-6px] ml-[184px] transform rotate-45',
  bottomLeft: 'top-0 mt-[-6px] transform rotate-45',
  bottomCenter: 'top-0 mt-[-6px] ml-[94px] transform rotate-45',
  bottomRight: 'top-0 mt-[-6px] ml-[184px] transform rotate-45',
};
