import type { AppProps } from 'next/app';
import type { LevitySession } from 'next-auth';
import { SessionProvider, useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';

export const withLevityAuthV2Wrapper = (
  CustomApp: ({ Component, pageProps: { session, ...pageProps } }: AppProps) => JSX.Element,
) => {
  function CustomLevityAuth2App(appProps: AppProps) {
    return <SessionProvider>{CustomApp(appProps)}</SessionProvider>;
  }

  return CustomLevityAuth2App;
};

export const useAuthSession = () => {
  const [session, setUser] = useState<LevitySession | null>(null);
  const retrievedSession = useSession();
  useEffect(() => {
    if (retrievedSession.status === 'loading') {
      return;
    }
    if (retrievedSession?.data) {
      setUser(retrievedSession.data as LevitySession);
    }
  }, [retrievedSession]);

  return session;
};
