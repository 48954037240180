import type { InputHTMLAttributes } from 'react';

import type { TooltipContent } from '../../../tooltip';
import type { LabelAction } from '../label';

export enum InputHeight {
  Small = 'small',
  Medium = 'medium',
}

export interface InputRawProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  isError?: boolean;
}

export interface InputProps extends InputRawProps {
  label?: string;
  tooltip?: TooltipContent;
  supportText?: string;
  isOptional?: boolean;
  labelAction?: LabelAction;
  height?: InputHeight;
}
