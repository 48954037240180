import Image from 'next/image';
import React from 'react';

import { Button, ButtonVariant } from '@/ui-library';

import type { TestFlowStatus } from '../../utils/types/test-flow.types';

export const HubspotUpdateTicketProperty = ({
  onCancel,
  onConfirm,
}: {
  data?: string[];
  onCancel: () => void;
  onConfirm: () => void;
  status?: TestFlowStatus;
}) => (
  <>
    <h2 className='text-NeutralDarkDarkest text-lg not-italic font-black mb-5'>{`We've updated the ticket property`}</h2>
    <p className='text-NeutralDarkLight mb-3'>Check in Hubspot</p>
    <div className='w-full flex gap-2 justify-center'>
      <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
        Cancel
      </Button>
      <Button variant={ButtonVariant.Primary} onClick={onConfirm}>
        <picture className='p-1 rounded-md bg-white'>
          <Image width={20} height={20} className='object-cover' src='/imgs/icons/hubspotV1.svg' alt='Hubspot Icon' />
        </picture>
        View in Hubspot
      </Button>
    </div>
  </>
);
