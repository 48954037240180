import { AppIconColourVariant } from './app-icon.types';

export const getBgColor = (colourVariant: AppIconColourVariant, isPlaceholder: boolean) => {
  if (colourVariant === AppIconColourVariant.LIGHT && isPlaceholder) {
    return 'bg-NeutralLightLight';
  }
  if (colourVariant === AppIconColourVariant.LIGHT && !isPlaceholder) {
    return 'bg-NeutralLightLight';
  }
  if (colourVariant === AppIconColourVariant.DEFAULT && isPlaceholder) {
    return 'bg-[#E7E7F8]';
  }
  return 'bg-NeutralLightLightest';
};
