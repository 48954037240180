import { ButtonSize, ButtonVariant } from './button.types';

export const getVariantStyles = (variant: ButtonVariant) => {
  let variantStyles;
  switch (variant) {
    case ButtonVariant.Secondary:
      variantStyles =
        'border-HighlightDarkest text-HighlightDarkest hover:border-HighlightDark hover:text-HighlightDark active:border-HighlightMedium active:text-HighlightMedium disabled:border-NeutralLightDarkest disabled:text-NeutralLightDarkest';
      break;
    case ButtonVariant.Tertiary:
      variantStyles =
        'border-transparent text-HighlightDarkest hover:text-HighlightDark active:text-HighlightMedium disabled:text-NeutralLightDarkest';
      break;
    case ButtonVariant.Danger:
      variantStyles =
        'bg-red-500 text-white border-transparent hover:bg-red-400 active:bg-red-500 disabled:text-NeutralLightDarkest focus:bg-red-400 focus:outline-red-600';
      break;
    case ButtonVariant.PrimaryLight:
      variantStyles =
        'bg-NeutralLightMedium text-NeutralDarkLight border-transparent hover:bg-HighlightLight hover:text-NeutralDarkMedium active:bg-HighlightLight active:text-NeutralDarkMedium disabled:bg-NeutralLightMedium disabled:text-NeutralDarkUltraLight';
      break;
    default:
      variantStyles =
        'border-transparent text-white bg-HighlightDarkest hover:bg-HighlightDark active:bg-HighlightMedium disabled:bg-NeutralLightDarkest';
      break;
  }

  return variantStyles;
};

export const getProgressbarColor = (variant: ButtonVariant) => {
  if (variant === ButtonVariant.Primary) return 'text-white';
  if (variant === ButtonVariant.PrimaryLight) return 'text-NeutralDarkLight disabled: text-NeutralDarkUltraLight';

  return 'group-disabled:text-NeutralLightDarkest text-HighlightDarkest';
};

export const getSizeStyles = (size: ButtonSize) => {
  if (size === ButtonSize.Large) return 'px-14 py-[19px] text-[15px] leading-[18px]';
  if (size === ButtonSize.Small) return 'px-2 py-2 text-xs leading-[12px] ';
  return 'px-4 py-3 text-xs leading-[14px]';
};
