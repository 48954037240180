import Image from 'next/image';
import React from 'react';

import { Button, ButtonVariant } from '@/ui-library';

import { TestFlowStatus } from '../../utils/types/test-flow.types';

export const FrontReplyToMessage = ({
  onCancel,
  onConfirm,
  status,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  status: TestFlowStatus;
}) => {
  if (status === TestFlowStatus.WARNING) {
    return (
      <>
        <h2 className='text-NeutralDarkDarkest text-base not-italic font-black mb-5'>
          Your flow executed properly, but...
        </h2>
        <div className='flex flex-col gap-2 justify-center mb-8'>
          <p className='text-NeutralDarkLight mb-3'>
            No labels or messages were found to classify. Try to add more labels in the AI Block or choose more inboxes
            and try again.
          </p>
          <div className='w-full flex gap-2 justify-center'>
            <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
              Got it
            </Button>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <h2 className='text-NeutralDarkDarkest text-base not-italic font-black mb-5'>{`We've sent a reply`}</h2>
      <p className='text-NeutralDarkDark mb-5 text-sm'>
        Check the archived conversations in Front to see the reply sent
      </p>
      <div className='w-full flex gap-2 justify-center'>
        <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
          Close
        </Button>
        <Button variant={ButtonVariant.Primary} onClick={onConfirm}>
          <picture className='p-1 rounded-md bg-white'>
            <Image width={20} height={20} className='object-cover' src='/imgs/icons/front.svg' alt='Front Icon' />
          </picture>
          Open in Front
        </Button>
      </div>
    </>
  );
};
