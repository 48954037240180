import React from 'react';

import type { PageContentProps } from '../layout.types';

export const PageContentTight: React.FC<PageContentProps> = (props) => {
  const { children } = props;

  return (
    <div className='min-h-full bg-NeutralLightLight px-4 sm:px-6 lg:px-8 py-14'>
      <div className='max-w-[1400px] m-auto'>{children}</div>
    </div>
  );
};
