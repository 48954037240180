import { useEffect } from 'react';

import { env } from '@/lib/env';

const HubSpotTrackingCode = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = `//js.hs-scripts.com/${env.NEXT_PUBLIC_HUBSPOT_ID}.js`;
    document.body.append(script);

    const style = document.createElement('style');
    style.innerHTML = `
            #hubspot-messages-iframe-container {
                visibility: hidden;
            }
        `;
    document.head.append(style);
  }, []);

  return null;
};

export default HubSpotTrackingCode;
