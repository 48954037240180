import { CopyTagTypes, OperationDefinition } from '../../../../flows/types';
import { CUSTOM, TAG } from '../../constants';

type SlackOperationKeys = 'GetMessages' | 'SendChannelMessage';

export const definition: Record<SlackOperationKeys, OperationDefinition> = {
  GetMessages: {
    id: 'slack-get-messages',
    name: 'Get Messages',
    description: 'New message received',
    icon: 'slack',
    hasDynamicInputs: false,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'When message is received',
      },
      actionDefinition: {
        elementTags: ['New message'],
        action: `When ${TAG} is received`,
      },
    },
  },
  SendChannelMessage: {
    id: 'slack-send-channel-message',
    name: 'Send Channel Message',
    description: 'Send Channel Message',
    icon: 'slack',
    hasDynamicInputs: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Create a channel message',
        tooltips: {
          description:
            'Write your message here. You can also include text generated from previous steps in this workflow.',
        },
      },
      interactiveParameters: {
        label: 'Channel message text',
        title: 'Send channel message',
        tooltips: {
          description:
            'Write your message here. You can also include text generated from previous steps in this workflow.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Create a message with ${CUSTOM}`,
      },
    },
  },
};
