import '../styles/globals.css';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { type AppProps } from 'next/app';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { compose } from 'recompose';

import { withLevityAuthV2Wrapper } from '@/levity/auth/compatibility-components';
import type { ToastType } from '@/ui-library';
import { ConfirmDialogProvider } from '@/ui-library';

import HubSpotTrackingCode from '../components/HubspotTrackingCode';
import { Toast } from '../ui-library/toast/toast.component';
import { api } from '../utils/api';
import { Layouts, NoLayout } from './_layouts';

declare module 'notistack' {
  interface VariantOverrides {
    // remove the default variants
    default: false;
    warning: false;
    success: false;
    error: false;
    info: false;

    // adds `customToast` variant plus custom props in addition to notistack props
    customToast: {
      title?: string;
      description?: string;
      type?: ToastType;
    };
  }
}

dayjs.extend(utc);

function CustomApp({ Component, pageProps }: AppProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const Layout = Layouts[Component.Layout] ?? NoLayout;

  return (
    <>
      <HubSpotTrackingCode />
      <Layout>
        <SnackbarProvider
          Components={{
            customToast: Toast,
          }}
        />
        <ConfirmDialogProvider>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...pageProps} />
        </ConfirmDialogProvider>
      </Layout>
    </>
  );
}

const fe = withLevityAuthV2Wrapper(CustomApp);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default compose(api.withTRPC)(fe);
