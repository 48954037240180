import React from 'react';

import type { PageContentProps } from '../layout.types';

export const PageContent: React.FC<PageContentProps> = (props) => {
  const { children } = props;

  return (
    <div className='min-h-full bg-NeutralLightLight px-16 py-14'>
      <div className='max-w-[1000px] m-auto'>{children}</div>
    </div>
  );
};
