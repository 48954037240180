import type { HTMLAttributes, ReactNode } from 'react';

export enum TooltipPosition {
  TopLeft = 'topLeft',
  TopCenter = 'topCenter',
  TopRight = 'topRight',
  BottomLeft = 'bottomLeft',
  BottomCenter = 'bottomCenter',
  BottomRight = 'bottomRight',
}

export interface TooltipContent {
  title?: ReactNode;
  description?: ReactNode;
}

export interface TooltipDefinition extends TooltipContent {
  usePortal?: boolean;
}

export interface TooltipProps extends Omit<HTMLAttributes<HTMLDivElement>, 'content'> {
  content?: TooltipContent;
  position?: TooltipPosition;
  hide?: boolean;
  hideTip?: boolean;
  children?: ReactNode;
  placeholder?: string;
  usePortal?: boolean;
}
export interface TooltipContentProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  position: TooltipPosition;
  hideTip: boolean;
  tooltipPosition: { top: number; left: number };
  usePortal?: boolean;
}
