import React from 'react';

import { FeatureFlag, FeatureFlagsContextProps, FeatureFlagsProviderProps, FeatureFlagsType } from './types';

export const context = React.createContext<FeatureFlagsContextProps>({
  featureFlags: null,
  setFeatureFlags: () => {},
});

export const Provider: React.FC<FeatureFlagsProviderProps> = ({ children }) => {
  const [featureFlags, setFeatureFlags] = React.useState<FeatureFlagsType | null>(null);

  return <context.Provider value={{ featureFlags, setFeatureFlags }}>{children}</context.Provider>;
};

export const useFeatureFlags = (flag?: FeatureFlag) => {
  const { featureFlags, setFeatureFlags } = React.useContext(context);
  const flagValue = featureFlags && flag ? featureFlags[flag] : undefined;
  const isLoading = featureFlags === null;

  return { featureFlags, setFeatureFlags, flagValue, isLoading };
};
