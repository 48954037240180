import { SnackbarContent, useSnackbar } from 'notistack';
import React from 'react';

import { cn } from '@/utils/styles';

import { Icons } from '../icons';
import { backgroundColor } from './toast.core';
import type { ToastProps } from './toast.types';
import { ToastType } from './toast.types';

export const ToastComponent = React.forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
  const { id, title, description, type = ToastType.Informative, ...other } = props;

  // Close functionality
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = () => {
    closeSnackbar(id);
  };

  const bgColor = backgroundColor(type);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SnackbarContent ref={ref} role='alert' {...other}>
      <div className={cn('flex w-fit min-w-[280px] max-w-[500px] items-center gap-4 rounded-2xl p-4', bgColor)}>
        {type === ToastType.Informative && <Icons.Info />}
        {type === ToastType.Success && <Icons.Success />}
        {type === ToastType.Warning && <Icons.Warning />}
        {type === ToastType.Error && <Icons.Error />}

        <div className='max-w-[180px]'>
          {title && <h5 className='text-[13px] font-bold leading-4 text-NeutralDarkDarkest'>{title}</h5>}
          {description && (
            <p className='text-[13px] font-normal leading-4 tracking-[.01] text-NeutralDarkMedium'>{description}</p>
          )}
        </div>
        <div className='ml-auto flex items-center'>
          <button type='button' onClick={handleDismiss}>
            <Icons.Close />
          </button>
        </div>
      </div>
    </SnackbarContent>
  );
});

ToastComponent.displayName = 'Toast';

export const Toast = ToastComponent;
