import Image from 'next/image';
import React from 'react';

import { Button, ButtonVariant } from '@/ui-library';

import { FrontTeammate } from '../../levity/integrations/registry/customer-service/providers/front.types';
import { TestFlowStatus } from '../../utils/types/test-flow.types';

export const FrontUpdateConversationAssignee = ({
  onCancel,
  onConfirm,
  data,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  status: TestFlowStatus;
  data?: { teammate: FrontTeammate };
}) => {
  return (
    <>
      <h2 className='text-NeutralDarkDarkest text-base not-italic font-black mb-5'>
        {`We've assigned ${data?.teammate?.first_name} ${data?.teammate?.last_name} to the conversation`}
      </h2>
      <p className='text-NeutralDarkDark mb-5 text-sm'>Check your inbox in Front to see the updated conversation</p>
      <div className='w-full flex gap-2 justify-center'>
        <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
          Close
        </Button>
        <Button variant={ButtonVariant.Primary} onClick={onConfirm}>
          <picture className='p-1 rounded-md bg-white'>
            <Image width={20} height={20} className='object-cover' src='/imgs/icons/front.svg' alt='Front Icon' />
          </picture>
          Open in Front
        </Button>
      </div>
    </>
  );
};
