import Image from 'next/image';
import React from 'react';

import { getVariantStyles } from './tag.core';
import { type TagProps, TagVariant } from './tag.types';

export const Tag: React.FC<TagProps> = (props) => {
  const { text, image, variant = TagVariant.Primary } = props;

  const variantStyles = getVariantStyles(variant);

  return (
    <div className={variantStyles.container}>
      {image && <Image src={image.src} width={image.width} height={image.height} alt={image.alt} />}
      <span className={variantStyles.text}>{text}</span>
    </div>
  );
};
