import React from 'react';
import CircleLoader from 'react-spinners/ClipLoader';

import { cn } from '@/utils/styles';

import type { CollectionLoadingProps } from '../collection.types';

export const CollectionLoading: React.FC<CollectionLoadingProps> = (props) => {
  const { children, className, ...rest } = props;

  return (
    <div className={cn('flex flex-col gap-6 items-center justify-center', className)} {...rest}>
      {children}
      {!children && <CircleLoader color='#5557F6' />}
    </div>
  );
};
