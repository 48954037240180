import React from 'react';

import { IconProps } from '../icons.types';

export const CompactIcon: React.FC<IconProps> = (props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M15 16.25L15 3.75' stroke='currentColor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M6.25 6.875L3.125 10L6.25 13.125'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10 10L3.75 10' stroke='currentColor' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
