import React from 'react';

import { IconProps } from '../icons.types';

export const WarningIcon: React.FC<IconProps> = (props) => (
  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <mask id='mask0_154_7748' maskUnits='userSpaceOnUse' x='1' y='1' width='23' height='23'>
      <ellipse cx='12.7092' cy='12.5' rx='11.0002' ry='11' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_154_7748)'>
      <rect x='0.708984' y='0.5' width='24.0004' height='24' fill='#FFB37C' />
    </g>
    <path
      d='M12.1564 6.56006C12.3307 6.48331 12.5191 6.44375 12.7095 6.44391C12.8999 6.44396 13.0881 6.48367 13.2622 6.56051C13.4363 6.63735 13.5925 6.74964 13.7208 6.89021C13.8491 7.03079 13.9466 7.19655 14.0073 7.37694C14.0679 7.55733 14.0903 7.74838 14.073 7.93791L13.527 13.9469C13.5058 14.1489 13.4105 14.3359 13.2596 14.4719C13.1086 14.6078 12.9127 14.683 12.7095 14.683C12.5064 14.683 12.3105 14.6078 12.1595 14.4719C12.0085 14.3359 11.9133 14.1489 11.892 13.9469L11.3445 7.93791C11.3272 7.74826 11.3497 7.55708 11.4104 7.37659C11.4711 7.19609 11.5689 7.03026 11.6973 6.88966C11.8258 6.74907 11.9821 6.63681 12.1564 6.56006Z'
      fill='white'
    />
    <path
      d='M14.0181 17.2477C14.0181 17.9703 13.4323 18.5562 12.7096 18.5562C11.9869 18.5562 11.4011 17.9703 11.4011 17.2477C11.4011 16.525 11.9869 15.9392 12.7096 15.9392C13.4323 15.9392 14.0181 16.525 14.0181 17.2477Z'
      fill='white'
    />
  </svg>
);
