import { useCallback, useState } from 'react';

export const useToggle = (initialValue: boolean) => {
  const [isOn, setIsOn] = useState(initialValue);

  const toggle = useCallback((value?: boolean) => {
    if (typeof value === 'boolean') {
      return setIsOn(value);
    }

    return setIsOn((v) => !v);
  }, []);

  return { isOn, toggle };
};
