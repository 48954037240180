import { Combobox as HeadlessUICombobox } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CircleLoader from 'react-spinners/ClipLoader';

import { QuestionIcon, Tooltip } from '@/ui-library/tooltip';
import { cn } from '@/utils/styles';

import { Label } from '../label';
import { SupportText } from '../support-text';
import { ComboboxHeight, type ComboBoxOption, type ComboBoxProps } from './combobox.types';

const ComboBoxRaw: React.FC<ComboBoxProps> = ({
  name,
  options,
  placeholder,
  isError = false,
  disabled = false,
  className = '',
  loading = false,
  height = ComboboxHeight.Medium,
  value: controlledValue,
  ...props
}) => {
  const { setValue, watch } = useFormContext();
  const value = controlledValue || watch(name);

  const [query, setQuery] = useState('');

  const setSelectedValue = (option: ComboBoxOption) => {
    setValue(name, option.id);
    setQuery('');
    props.onChange?.(option.id);
  };

  const filteredValues =
    query === ''
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  const baseStyle = cn(
    'block w-full resize-y border-0 px-4 placeholder:text-NeutralDarkLightest outline-none text-sm',
    disabled || loading ? 'bg-NeutralLightLight text-NeutralLightDarkest' : 'text-NeutralDarkDarkest',
    height === ComboboxHeight.Small ? 'h-[34px] py-0.5' : 'h-12 py-3.5',
  );
  const ringStyles = isError
    ? 'ring-inset ring-SupportErrorMedium ring-[1.5px]'
    : cn(
        'ring-inset ring-1 ring-NeutralLightDarkest focus:ring-HighlightMedium focus:ring-[1.5px]',
        !disabled && !loading && 'hover:ring-HighlightMedium hover:ring-[1.5px]',
      );

  const roundedStyles = (isOpen: boolean) => cn(isOpen ? 'rounded-t-lg rounded-x-lg' : 'rounded-lg');

  const inputClassName = `${baseStyle} ${ringStyles} ${className}`;

  return (
    <HeadlessUICombobox
      as='div'
      value={options.find((option) => option.id === value)}
      onChange={setSelectedValue}
      disabled={disabled || loading}>
      {({ open }) => (
        <div className='relative'>
          <HeadlessUICombobox.Button className='w-full'>
            <div className='relative'>
              {loading ? (
                <div className='absolute flex items-center justify-center h-full pl-3 gap-2'>
                  <CircleLoader color='#5557F6' size={20} cssOverride={{ borderWidth: '2px' }} />
                  <span className='text-NeutralDarkLightest  text-sm'>Loading...</span>
                </div>
              ) : null}
              <HeadlessUICombobox.Input
                placeholder={loading ? '' : placeholder ?? 'Type to search or select an option...'}
                className={`${inputClassName} ${roundedStyles(open)}`}
                aria-disabled={disabled || loading}
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(option: { label: string }) => option?.label}
              />
              <div
                className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'
                aria-hidden='true'>
                <div
                  className={cn(
                    'w-5 h-5 mr-1',
                    disabled || loading ? 'text-NeutralLightDarkest' : 'text-NeutralDarkLightest',
                  )}>
                  {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </div>
              </div>
            </div>
          </HeadlessUICombobox.Button>

          <HeadlessUICombobox.Options className='absolute z-10 -mt-1 w-full overflow-hidden bg-white text-base focus:outline-none sm:text-sm ring-inset ring-HighlightMedium ring-[1.5px] p-3 rounded-b-lg'>
            <div className='overflow-y-auto max-h-60'>
              {filteredValues.length === 0 ? (
                <span className='text-NeutralDarkLightest text-sm ml-1'>No options found</span>
              ) : (
                filteredValues.map((option) => (
                  <HeadlessUICombobox.Option
                    key={option.id}
                    value={option}
                    className={({ active }) =>
                      cn(
                        'relative cursor-default select-none p-3 font-normal rounded-md hover:bg-NeutralLightLight',
                        active ? 'bg-NeutralLightLight text-HighlightDarkest' : 'text-NeutralDarkDarkest',
                      )
                    }>
                    {({ selected }) => (
                      <span className={cn('block truncate', selected && 'font-semibold text-HighlightDarkest')}>
                        {option.label}
                      </span>
                    )}
                  </HeadlessUICombobox.Option>
                ))
              )}
            </div>
          </HeadlessUICombobox.Options>
        </div>
      )}
    </HeadlessUICombobox>
  );
};

export const ComboBox: React.FC<ComboBoxProps> = ({
  name,
  label,
  options,
  tooltip,
  supportText,
  disabled,
  className,
  isOptional,
  labelAction,
  height = ComboboxHeight.Medium,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message as string;
  const isError = Boolean(errorMessage);

  return (
    <div className='flex flex-col gap-2'>
      {label && (
        <div className='flex items-center gap-1 '>
          <Label htmlFor={name}>
            <div className='flex items-center gap-1'>
              {label}
              {isOptional && <span className='font-normal text-NeutralDarkLightest'>Optional</span>}
              {labelAction && (
                <button onClick={labelAction.onAction} className='ml-1 font-medium text-HighlightDarkest'>
                  {labelAction.text}
                </button>
              )}
            </div>
          </Label>
          {tooltip && (
            <Tooltip content={tooltip}>
              <QuestionIcon />
            </Tooltip>
          )}
        </div>
      )}
      <ComboBoxRaw
        name={name}
        options={options}
        disabled={disabled}
        isError={isError}
        height={height}
        className={className}
        {...props}
      />
      {supportText || errorMessage ? <SupportText text={errorMessage ?? supportText} isError={isError} /> : null}
    </div>
  );
};
