import React, { createContext, useContext, useMemo, useState } from 'react';

import { ConfirmDialog } from './confirm-dialog.component';
import type { ConfirmDialogProviderProps, ConfirmDialogSettings } from './confirm-dialog.types';

const ConfirmDialogContext = createContext<{
  showDialog(settings: ConfirmDialogSettings): void;
} | null>(null);

export const ConfirmDialogProvider = ({ children }: ConfirmDialogProviderProps) => {
  const [shown, setShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultSettings: ConfirmDialogSettings = {
    title: 'Confirm',
    confirmMessage: 'Are you sure?',
    async onConfirm() {
      setShown(false);
    },
  };
  const [dialogSettings, setDialogSettings] = useState<ConfirmDialogSettings>(defaultSettings);

  const showDialog = (opts?: Partial<ConfirmDialogSettings>) => {
    setShown(true);
    setDialogSettings({
      confirmMessage: opts?.confirmMessage ?? defaultSettings.confirmMessage,
      onConfirm: opts?.onConfirm ?? defaultSettings.onConfirm,
      title: opts?.title ?? defaultSettings.title,
      info: opts?.info,
      confirmButtonText: opts?.confirmButtonText,
      cancelButtonText: opts?.cancelButtonText,
      confirmButtonVariant: opts?.confirmButtonVariant,
      secondaryConfirmButtonText: opts?.secondaryConfirmButtonText,
      onSecondaryConfirm: opts?.onSecondaryConfirm ?? defaultSettings.onSecondaryConfirm,
    });
  };

  const hideAlert = () => setShown(false);
  const onConfirm = async () => {
    setLoading(true);
    if (dialogSettings.onConfirm) {
      await dialogSettings.onConfirm();
    }
    setLoading(false);
    setShown(false);
  };
  const onSecondaryConfirm = async () => {
    setLoading(true);
    if (dialogSettings.onSecondaryConfirm) {
      await dialogSettings.onSecondaryConfirm();
    }
    setLoading(false);
    setShown(false);
  };

  const providerValue = useMemo(() => ({ showDialog }), []);

  return (
    <ConfirmDialogContext.Provider value={providerValue}>
      <ConfirmDialog
        open={shown}
        onClose={hideAlert}
        onConfirm={onConfirm}
        message={dialogSettings.confirmMessage}
        title={dialogSettings.title}
        info={dialogSettings.info}
        confirmButtonText={dialogSettings.confirmButtonText}
        confirming={loading}
        cancelButtonText={dialogSettings.cancelButtonText}
        confirmButtonVariant={dialogSettings.confirmButtonVariant}
        onSecondaryConfirm={onSecondaryConfirm}
        secondaryConfirmButtonText={dialogSettings.secondaryConfirmButtonText}
      />
      {children}
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmDialog = () => {
  const context = useContext(ConfirmDialogContext);
  if (!context) {
    throw new Error('Please use <ConfirmDialogProvider /> in parent component.');
  }

  return context;
};
