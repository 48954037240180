export interface ProgressbarProps {
  progress: number;
  height?: ProgressbarHeight;
  hasMin?: boolean;
}

export enum ProgressbarHeight {
  default = 'default',
  small = 'small',
  tight = 'tight',
}

export function ProgressBar(props: ProgressbarProps) {
  const { height, progress, hasMin } = props;

  const getHeightStyle = (height?: ProgressbarHeight) => {
    if (height === ProgressbarHeight.tight) return 'h-[6px]';
    if (height === ProgressbarHeight.small) return 'h-[8px]';
    return 'h-[11px]';
  };
  const heightStyle = getHeightStyle(height);
  const progressNum = hasMin && progress < 3 ? 3 : progress;

  return (
    <div className={`w-full bg-HighlightLightest rounded-full overflow-hidden ${heightStyle}`}>
      <div className={`bg-HighlightDarkest rounded-full ${heightStyle}`} style={{ width: `${progressNum}%` }} />
    </div>
  );
}
