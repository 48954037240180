import React from 'react';

import { IconProps } from '../icons.types';

export const GitBranchIcon: React.FC<IconProps> = (props) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.78125 15.1875C5.86856 15.1875 6.75 14.3061 6.75 13.2188C6.75 12.1314 5.86856 11.25 4.78125 11.25C3.69394 11.25 2.8125 12.1314 2.8125 13.2188C2.8125 14.3061 3.69394 15.1875 4.78125 15.1875Z'
      stroke='currentColor'
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.2188 6.75C14.3061 6.75 15.1875 5.86856 15.1875 4.78125C15.1875 3.69394 14.3061 2.8125 13.2188 2.8125C12.1314 2.8125 11.25 3.69394 11.25 4.78125C11.25 5.86856 12.1314 6.75 13.2188 6.75Z'
      stroke='currentColor'
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.78125 6.75C5.86856 6.75 6.75 5.86856 6.75 4.78125C6.75 3.69394 5.86856 2.8125 4.78125 2.8125C3.69394 2.8125 2.8125 3.69394 2.8125 4.78125C2.8125 5.86856 3.69394 6.75 4.78125 6.75Z'
      stroke='currentColor'
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.78125 11.25V10.6875C4.78126 10.2399 4.95905 9.81074 5.27551 9.49429C5.59197 9.17783 6.02118 9.00005 6.46872 9.00005L11.5313 8.99995C11.9788 8.99995 12.408 8.82217 12.7245 8.50571C13.041 8.18926 13.2187 7.76005 13.2188 7.31251V6.75'
      stroke='currentColor'
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.78125 6.75V11.25'
      stroke='currentColor'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
