import React from 'react';

import { Tooltip } from '../../tooltip';
import type { AppIconCountProps } from '../app-icon.types';
import { AppIconContainer } from './app-icon-container.component';

export const AppIconCount: React.FC<AppIconCountProps> = (props) => {
  const { tooltip, size, colourVariant, count } = props;

  return (
    <Tooltip
      content={tooltip?.content}
      placeholder={tooltip?.placeholder}
      hide={tooltip?.hide || false}
      hideTip={tooltip?.hideTip || true}>
      <AppIconContainer size={size} colourVariant={colourVariant}>
        <span className='font-bold text-[13px] text-HighlightDark'>{`+${count}`}</span>
      </AppIconContainer>
    </Tooltip>
  );
};
