import type { PostHog } from 'posthog-js';

import { getPostHogInstance } from './tracking';

type GetActiveMatchingSurveysType = PostHog['getActiveMatchingSurveys'];
type SurveyCallback = Parameters<GetActiveMatchingSurveysType>[0];
type Surveys = Parameters<SurveyCallback>[0];
export type Survey = Surveys[number] & { id: string };

export async function getAvailableSurveys(): Promise<Survey[]> {
  const posthog = getPostHogInstance();
  const availableSurveys = await new Promise<Survey[]>((resolve, reject) => {
    try {
      posthog.getActiveMatchingSurveys((surveys) => {
        resolve(surveys as Survey[]);
      });
    } catch (error) {
      reject(error);
    }
  });
  return availableSurveys;
}

export async function getAvailableSurveyByName(name: string): Promise<Survey | undefined> {
  const surveys = await getAvailableSurveys();

  return surveys.find((survey: Survey) => survey.name === name);
}
