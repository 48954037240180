import Image from 'next/image';
import React from 'react';

import { CustomField } from '@/levity/integrations/registry/customer-service/providers/front.types';
import { Button, ButtonVariant } from '@/ui-library';

import { TestFlowStatus } from '../../utils/types/test-flow.types';

export const FrontUpdateConversationCustomFields = ({
  data,
  onCancel,
  onConfirm,
}: {
  data?: { id: string; customFields: CustomField[] };
  onCancel: () => void;
  onConfirm: () => void;
  status: TestFlowStatus;
}) => {
  const customFields = Object.keys(data?.customFields || {});
  const areAvailableCustomFields = data?.customFields && customFields.length > 0;
  return (
    <>
      <h2 className='text-NeutralDarkDarkest text-base not-italic font-black mb-5'>
        {`We've updated the latest conversation's custom fields`}
      </h2>
      <div className='flex flex-row gap-2 justify-center mb-8'>
        {areAvailableCustomFields &&
          customFields.map((field) => (
            <div key={field} className='text-black p-5 h-10 flex items-center justify-center text-xs'>
              {field}
            </div>
          ))}
      </div>
      <div className='w-full flex gap-2 justify-center'>
        <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
          Close
        </Button>
        <Button variant={ButtonVariant.Primary} onClick={onConfirm}>
          <picture className='p-1 rounded-md bg-white'>
            <Image width={20} height={20} className='object-cover' src='/imgs/icons/front.svg' alt='Front Icon' />
          </picture>
          Open in Front
        </Button>
      </div>
    </>
  );
};
