import type { ButtonHTMLAttributes, ReactNode } from 'react';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Danger = 'danger',
  PrimaryLight = 'primary-light',
}

export enum ButtonSize {
  Default = 'default',
  Large = 'large',
  Small = 'small',
}

export enum ButtonIconPosition {
  Left = 'left',
  Right = 'right',
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  size?: ButtonSize;
  icon?: ReactNode;
  iconPosition?: ButtonIconPosition;
  variant?: ButtonVariant;
  isLoading?: boolean;
}
