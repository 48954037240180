import { BannerType, BannerVariant } from './banner.types';

const styles = {
  base: {
    [BannerVariant.Condensed]:
      'z-50 flex sm:flex-row flex-col md:h-14 bg-HighlightLightest pl-6 pr-4 py-2 justify-between',
    [BannerVariant.Default]: 'z-50 flex flex-col gap-6 bg-HighlightLightest px-8 py-5',
  },
  descriptionContainer: {
    [BannerVariant.Condensed]: 'flex sm:flex-row flex-col gap-2',
    [BannerVariant.Default]: 'flex flex-col gap-2',
  },
  title: {
    [BannerVariant.Condensed]: 'text-sm font-extrabold leading-4 text-NeutralDarkDarkest whitespace-nowrap',
    [BannerVariant.Default]: 'text-sm font-extrabold leading-4 text-NeutralDarkDarkest',
  },
  description: {
    [BannerVariant.Condensed]:
      'text-[13px] font-normal leading-6 text-NeutralDarkMedium align-middle flex items-center',
    [BannerVariant.Default]: 'text-[13px] font-normal leading-6 text-NeutralDarkMedium',
  },
  iconBase: {
    [BannerVariant.Condensed]: 'flex items-center gap-1.5',
    [BannerVariant.Default]: 'flex items-center gap-1.5',
  },
  ctaContainer: {
    [BannerVariant.Condensed]: 'flex items-center justify-end gap-4 py-1 md:py-0',
    [BannerVariant.Default]: 'flex items-center justify-end gap-4',
  },
};

export const getStyle = (variant: BannerVariant) => {
  return {
    baseClassName: styles.base[variant],
    descriptionBaseClassName: styles.descriptionContainer[variant],
    titleClassName: styles.title[variant],
    descriptionClassName: styles.description[variant],
    iconBaseClassName: styles.iconBase[variant],
    ctaContainerClassName: styles.ctaContainer[variant],
  };
};

const bgColors = {
  [BannerType.Success]: 'bg-SupportSuccessLight',
  [BannerType.Warning]: 'bg-SupportWarningLight',
  [BannerType.Error]: 'bg-SupportErrorLight',
  [BannerType.Informative]: 'bg-HighlightLightest',
};

export const backgroundColor = (type: BannerType) => bgColors[type];
