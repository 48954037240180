import Image from 'next/image';
import React from 'react';

import { Button, ButtonVariant } from '@/ui-library';

export const OutlookMoveToFolder = ({
  data,
  onCancel,
  onConfirm,
}: {
  data?: { folderName?: string };
  onCancel: () => void;
  onConfirm: (foderName: string | undefined) => void;
}) => {
  const folderName = data?.folderName;
  return (
    <>
      <h2 className='text-NeutralDarkDarkest text-base not-italic font-black mb-5'>
        {`We've moved your latest email to the ${folderName} folder`}
      </h2>
      <p className='text-NeutralDarkDark mb-5 text-sm'>{`Check the ${folderName} folder to see the email`}</p>
      <div className='w-full flex gap-2 justify-center'>
        <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
          Close
        </Button>
        <Button variant={ButtonVariant.Primary} onClick={() => onConfirm(folderName?.toLowerCase())}>
          <picture className='p-1 rounded-md bg-white'>
            <Image width={20} height={20} className='object-cover' src='/imgs/icons/outlook.svg' alt='Outlook Icon' />
          </picture>
          Open in Outlook
        </Button>
      </div>
    </>
  );
};
