import PostHog from 'posthog-js';

import { env } from '@/lib/env';

import type {
  AlertProps,
  CustomEventProps,
  EventProps,
  IdentifyProps,
  InputChangedProps,
  ItemClickedProps,
  ModalProps,
  SurveyProps,
  WarningProps,
} from './constants/eventHandlers';
import { EventTypes } from './constants/eventTypes';

interface InitializationProps {
  apiKey: string;
  apiUrl: string;
}

let posthogInstance: ReturnType<typeof PostHog.init> | null = null;
const userInfo = {
  tenantId: '',
};

export function initializeEvents(props: InitializationProps): void {
  const { apiKey, apiUrl } = props;
  posthogInstance = PostHog.init(apiKey, {
    api_host: apiUrl,
    capture_pageview: env.NEXT_PUBLIC_IS_ANALYTICS_EVENTS_ENABLED,
    capture_pageleave: env.NEXT_PUBLIC_IS_ANALYTICS_EVENTS_ENABLED,
    capture_performance: env.NEXT_PUBLIC_IS_ANALYTICS_EVENTS_ENABLED,
    session_recording: {
      maskAllInputs: false,
    },
    opt_in_site_apps: true,
  });
}

export function getPostHogInstance(): typeof PostHog {
  if (!posthogInstance) {
    return {
      capture: () => {},
      // @ts-ignore
      onFeatureFlags() {
        console.log('PostHog not initialized');
      },
    };
  }
  return posthogInstance;
}

// #region Event Handlers
const captureEvent = (
  pogInstance: ReturnType<typeof PostHog.init> | null,
  eventType: EventTypes,
  props: EventProps,
) => {
  let isAnalyticsEnabled = false;

  // Note: this extra check is intentional to be fully deterministic when
  // the environment variable is set to a string value.
  if (typeof env.NEXT_PUBLIC_IS_ANALYTICS_EVENTS_ENABLED === 'boolean') {
    isAnalyticsEnabled = env.NEXT_PUBLIC_IS_ANALYTICS_EVENTS_ENABLED;
  } else {
    isAnalyticsEnabled = env.NEXT_PUBLIC_IS_ANALYTICS_EVENTS_ENABLED === 'true';
  }

  if (isAnalyticsEnabled) {
    pogInstance?.capture(eventType, {
      ...props,
      tenantId: userInfo.tenantId,
      distinctId: userInfo.tenantId,
    });
  }
};

export function trackItemClicked(props: ItemClickedProps) {
  captureEvent(getPostHogInstance(), EventTypes.ItemClicked, props);
}

export function trackInputChanged(props: InputChangedProps) {
  captureEvent(getPostHogInstance(), EventTypes.InputChanged, props);
}

export function trackCustomEvent(props: CustomEventProps) {
  captureEvent(getPostHogInstance(), EventTypes.CustomEvent, props);
}

export function trackModalOpened(props: ModalProps) {
  captureEvent(getPostHogInstance(), EventTypes.ModalOpened, props);
}

export function trackModalClosed(props: ModalProps) {
  captureEvent(getPostHogInstance(), EventTypes.ModalClosed, props);
}

export function trackSurveyResponse(props: SurveyProps) {
  captureEvent(getPostHogInstance(), EventTypes.SurveyResponse, props);
}

export function trackAlertDisplayed(props: AlertProps) {
  captureEvent(getPostHogInstance(), EventTypes.AlertDisplayed, props);
}

export function trackAlertDismissed(props: AlertProps) {
  captureEvent(getPostHogInstance(), EventTypes.AlertDismissed, props);
}

export function trackWarning(props: WarningProps) {
  captureEvent(getPostHogInstance(), EventTypes.Warning, {
    ...props,
    elementType: 'warning',
  });
}

export function identify(props: IdentifyProps) {
  if (env.NEXT_PUBLIC_IS_ANALYTICS_EVENTS_ENABLED) {
    getPostHogInstance()?.identify(props.distinctId, props);
  }
  userInfo.tenantId = props.tenantId;
}

export function reset(resetDevice = false) {
  if (env.NEXT_PUBLIC_IS_ANALYTICS_EVENTS_ENABLED) {
    getPostHogInstance()?.reset(resetDevice);
  }
}

// #endregion
