export interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  labelText?: string;
  size?: SwitchSize;
  labelPosition?: SwitchLabelPosition;
}

export enum SwitchSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum SwitchLabelPosition {
  LEFT = 'left',
  RIGHT = 'right',
}
