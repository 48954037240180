import { useRouter } from 'next/router';

import { Button, ButtonSize, ButtonVariant } from '@/ui-library';
import { URLs } from '@/utils/urls';

import { ProgressBar, ProgressbarHeight } from '../../progressbar';
import type { UsageIndicatorProps } from '../types';

export const UsageIndicator: React.FC<UsageIndicatorProps> = (props) => {
  const { planName = '', actionsUsed, actionsAvailable } = props;
  const router = useRouter();

  return (
    <div className='w-full px-2'>
      <div className='flex gap-2.5 items-center'>
        <span className='text-sm text-NeutralDarkDarkest text-[13px] leading-4 font-medium'>{planName}</span>
        <Button
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
          onClick={() => router.push(URLs.Plans().pathname)}>
          Manage
        </Button>
      </div>
      <div className='h-[14px]' />
      <div className='flex text-NeutralDarkMedium justify-between pb-1 text-xs'>
        <span>Actions</span>
        <span>{`${actionsUsed}/${actionsAvailable}`}</span>
      </div>
      <div className='h-1' />
      <ProgressBar hasMin progress={(actionsUsed / actionsAvailable) * 100} height={ProgressbarHeight.tight} />
      <div className='h-1' />
    </div>
  );
};
