export const LoadingLogo = () => (
  <div className='animate-bounce'>
    <svg width='64' height='64' viewBox='0 0 16 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.849 25.3899C11.3097 25.4801 7.4017 25.6609 6.16326 25.8723C4.74012 26.1152 2.69948 26.2341 1.06253 27.0422C-0.405329 28.3105 0.344191 29.4977 0.828108 30.1036C1.17028 30.532 2.64499 30.639 3.75903 30.6543C4.63394 30.6662 6.72325 30.4033 7.6498 30.3205C9.90681 29.8262 14.2075 28.9719 14.6922 28.5774C15.2981 28.0842 15.5659 27.6833 15.5304 27.0176C15.4473 25.4575 13.0145 25.1949 11.849 25.3899Z'
        fill='#2B1E6B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.1052 0.404593C9.24878 1.09136 7.88774 4.17806 5.42633 11.0155C2.96413 17.8556 2.54229 19.9963 3.43054 21.1474C4.20498 22.1515 4.84391 22.1856 9.88671 21.4909C12.4038 21.1444 13.7784 21.1205 14.7397 20.8005C15.701 20.4804 15.9803 19.7246 15.9973 19.123C16.0496 17.2447 15.3557 16.9207 11.6479 17.0926C10.095 17.1646 8.68307 17.168 8.50996 17.1003C7.75761 16.8059 8.09428 15.6046 11.1676 7.62307C13.3285 2.01104 13.431 0.93169 11.867 0.26521C10.9688 -0.117433 10.7326 -0.0987669 10.1052 0.404593Z'
        fill='#2B1E6B'
      />
    </svg>
    <div className='loader-shadow' />
  </div>
);
