import React, { forwardRef } from 'react';

import { cn } from '@/utils/styles';

import type { CollectionProps, ExtendedCollection } from './collection.types';
import { CollectionAddItem, CollectionEmpty, CollectionItem, CollectionLoading } from './components';

const CollectionComponent: React.ForwardRefRenderFunction<HTMLDivElement, CollectionProps> = (props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <div ref={ref} className={cn('flex flex-col gap-5', className)} {...rest} data-testid='collection'>
      {children}
    </div>
  );
};

export const Collection: ExtendedCollection<CollectionProps & React.RefAttributes<HTMLDivElement>> = Object.assign(
  forwardRef(CollectionComponent),
  {
    Item: CollectionItem,
    Loading: CollectionLoading,
    Empty: CollectionEmpty,
    AddItem: CollectionAddItem,
  },
);
