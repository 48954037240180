import { CopyTagTypes, OperationDefinition } from '@/levity/flows/types';

type GSheetsOperationKeys = 'UpdateWorksheetRow' | 'AddWorksheetRow' | 'OnRowCreated';

export const gsheetsOperationsDefinition: Record<GSheetsOperationKeys, OperationDefinition> = {
  UpdateWorksheetRow: {
    id: 'gsheets-update-worksheet-row',
    name: 'Update a row in a worksheet',
    description: 'Update Spreadsheet row',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      actionDefinition: {
        action: `Update Spreadsheet row`,
        customTags: [{ type: CopyTagTypes.PREVIOUS_OPERATION_ACTION }],
        elementTags: [],
      },
      operationContext: {
        title: 'Update Spreadsheet row',
        tooltips: {
          description: 'Choose a value to update a new row in your Google Sheet.',
        },
      },
      interactiveParameters: {
        label: 'Drive',
        title: 'Select the Drive',
        tooltips: {
          description: 'Choose the drive where your target Google Sheet is located.',
        },
      },
    },
    icon: 'gsheets',
  },
  AddWorksheetRow: {
    id: 'gsheets-add-worksheet-row',
    name: 'Add a row in a worksheet',
    description: 'Add Spreadsheet row',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      actionDefinition: {
        action: `Add Spreadsheet row`,
        customTags: [{ type: CopyTagTypes.PREVIOUS_OPERATION_ACTION }],
        elementTags: [],
      },
      operationContext: {
        title: 'Add Spreadsheet row',
        tooltips: {
          description: 'Choose a value to add a new row in your Google Sheet.',
        },
      },
      interactiveParameters: {
        label: 'Drive',
        title: 'Select the Drive',
        tooltips: {
          description: 'Choose the drive where your target Google Sheet is located.',
        },
      },
    },
    icon: 'gsheets',
  },
  OnRowCreated: {
    id: 'gsheets-on-worksheet-row-created',
    name: 'Triggered when a new Row is created',
    description: 'On Row Created',
    hasDynamicInputs: true,
    requiresAuth: true,
  },
};
