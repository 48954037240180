import React from 'react';
import { useFormContext } from 'react-hook-form';

import { cn } from '@/utils/styles';

import { QuestionIcon, Tooltip } from '../../../tooltip';
import { Label } from '../label';
import { SupportText } from '../support-text';
import { InputHeight, type InputProps, type InputRawProps } from './input.types';

const InputRaw: React.FC<InputRawProps> = ({
  name,
  placeholder,
  defaultValue = '',
  isError = false,
  disabled = false,
  className = '',
  value: controlledValue,
  height = InputHeight.Medium,
  ...props
}) => {
  const { register, watch } = useFormContext();

  const value = controlledValue || watch(name);

  // Styles
  const baseStyle = cn(
    'block w-full resize-y rounded-md border-0 px-4 text-NeutralDarkDarkest shadow-sm placeholder:text-NeutralDarkLightest outline-none text-sm disabled:bg-NeutralLightLight disabled:text-NeutralLightDarkest',
    height === InputHeight.Small ? 'h-[34px] py-0.5' : 'h-12 py-3.5',
  );

  const ringStyles = isError
    ? 'ring-inset ring-SupportErrorMedium ring-[1.5px]'
    : 'ring-inset ring-1 ring-NeutralLightDarkest hover:ring-HighlightMedium hover:ring-[1.5px] focus:ring-HighlightMedium focus:ring-[1.5px] disabled:ring-NeutralLightDarkest disabled:ring-1';

  const classNames = cn(baseStyle, ringStyles, className);

  return (
    <input
      id={name}
      value={value}
      placeholder={placeholder}
      defaultValue={defaultValue}
      disabled={disabled}
      className={classNames}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...register(name)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export const Input: React.FC<InputProps> = ({
  name,
  label,
  placeholder,
  tooltip,
  supportText,
  disabled,
  className,
  isOptional,
  labelAction,
  height = InputHeight.Medium,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message as string;

  const isError = Boolean(errorMessage);

  return (
    <div className='flex flex-col gap-2'>
      {label && (
        <div className='flex items-center gap-1'>
          <Label htmlFor={name}>
            <div className='flex items-center gap-1'>
              {label}
              {isOptional && <span className='font-normal text-NeutralDarkLightest'>Optional</span>}
              {labelAction && (
                <button onClick={labelAction.onAction} className='ml-1 font-medium text-HighlightDarkest'>
                  {labelAction.text}
                </button>
              )}
            </div>
          </Label>
          {tooltip && (
            <Tooltip content={tooltip}>
              <QuestionIcon />
            </Tooltip>
          )}
        </div>
      )}
      <InputRaw
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        isError={isError}
        className={className}
        height={height}
        {...props}
      />
      {supportText || errorMessage ? <SupportText text={errorMessage ?? supportText} isError={isError} /> : null}
    </div>
  );
};
