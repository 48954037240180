import { TagVariant } from './tag.types';

export const getVariantStyles = (variant: TagVariant) => {
  switch (variant) {
    case TagVariant.Secondary:
      return {
        container:
          'flex items-center gap-2 text-xs border rounded w-fit px-2 py-1 hover:border-HighlightDarkest hover:bg-HighlightLightest border-NeutralLightDark bg-[#F8FBFF]',
        text: 'text-sm truncate',
      };

    case TagVariant.Primary:
      return {
        container: 'flex items-center gap-2 bg-HighlightLightest rounded px-2 py-0.5 w-fit',
        text: 'text-sm leading-6 text-HighlightDarkest truncate',
      };

    default:
      return {
        container: 'flex items-center gap-2 bg-HighlightLightest rounded px-2 py-0.5 w-fit',
        text: 'text-sm leading-6 text-HighlightDarkest truncate',
      };
  }
};
