import { ToastType } from './toast.types';

export const backgroundColor = (type: ToastType) => {
  // Background color
  let bgColor: string;
  switch (type) {
    case ToastType.Success:
      bgColor = 'bg-SupportSuccessLight';
      break;
    case ToastType.Warning:
      bgColor = 'bg-SupportWarningLight';
      break;
    case ToastType.Error:
      bgColor = 'bg-SupportErrorLight';
      break;
    default:
      bgColor = 'bg-HighlightLightest';
      break;
  }

  return bgColor;
};
