export const getGridCols = (numberOfValues: number) => {
  if (numberOfValues < 0 || numberOfValues > 9)
    console.error('Invalid number of values for Content switch component provided');

  switch (numberOfValues) {
    case 0:
      return 'grid-cols-0';
    case 1:
      return 'grid-cols-1';
    case 2:
      return 'grid-cols-2';
    case 3:
      return 'grid-cols-3';
    case 4:
      return 'grid-cols-4';
    case 5:
      return 'grid-cols-5';
    case 6:
      return 'grid-cols-6';
    case 7:
      return 'grid-cols-7';
    case 8:
      return 'grid-cols-8';
    case 9:
      return 'grid-cols-9';
    default:
      return 'grid-cols-2';
  }
};
