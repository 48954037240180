import Image from 'next/image';
import React from 'react';

import { cn } from '@/utils/styles';

import type { CollectionEmptyProps } from '../collection.types';

export const CollectionEmpty: React.FC<CollectionEmptyProps> = (props) => {
  const { text, children, className, ...rest } = props;
  return (
    <div className={cn('flex flex-col gap-6 items-center justify-center', className)} {...rest}>
      {children}
      {!children && (
        <>
          <Image src='/imgs/flows/empty.svg' width={80} height={80} alt='List empty' className='object-cover' />
          <span className='text-NeutralDarkLightest text-xs font-normal'>{text || 'Nothing here yet'}</span>
        </>
      )}
    </div>
  );
};
