import Image from 'next/image';
import React from 'react';

import { SuccessScreenComponentProps } from '@/hooks/useTestFlow';
import { Button, ButtonVariant } from '@/ui-library';
import { TestFlowStatus } from '@/utils/types/test-flow.types';

const MESSAGES = {
  WARNING_TITLE: 'Your flow executed properly, but...',
  WARNING_MESSAGE: 'Looks like there was an error while creating the draft. Please try again.',
  SUCCESS_TITLE: "We've created a draft for you",
  SUCCESS_SUBTITLE: 'Check it in your inbox',
} as const;

const WarningView: React.FC<{ onCancel: () => void }> = ({ onCancel }) => (
  <>
    <h2 className='text-NeutralDarkDarkest text-base not-italic font-black mb-5' role='alert'>
      {MESSAGES.WARNING_TITLE}
    </h2>
    <div className='flex flex-col gap-2 justify-center mb-8'>
      <p className='text-NeutralDarkLight mb-3'>{MESSAGES.WARNING_MESSAGE}</p>
      <div className='w-full flex gap-2 justify-center'>
        <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
          Got it
        </Button>
      </div>
    </div>
  </>
);

const SuccessView: React.FC<{
  onCancel: () => void;
  onConfirm: () => void;
}> = ({ onCancel, onConfirm }) => (
  <>
    <h2 className='text-NeutralDarkDarkest text-base not-italic font-black mb-2'>{MESSAGES.SUCCESS_TITLE}</h2>
    <p className='text-NeutralDarkDark mb-5 text-sm'>{MESSAGES.SUCCESS_SUBTITLE}</p>
    <div className='w-full flex gap-2 justify-center'>
      <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
        Close
      </Button>
      <Button variant={ButtonVariant.Primary} onClick={onConfirm} aria-label='Open in Outlook'>
        <picture className='p-1 rounded-md bg-white'>
          <Image
            width={20}
            height={20}
            className='object-cover'
            src='/imgs/icons/outlook.svg'
            alt='Outlook Icon'
            priority
          />
        </picture>
        Open in Outlook
      </Button>
    </div>
  </>
);

export const OutlookDraftReply: React.FC<SuccessScreenComponentProps> = ({ onCancel, onConfirm, status }) => {
  return status === TestFlowStatus.WARNING ? (
    <WarningView onCancel={onCancel} />
  ) : (
    <SuccessView onCancel={onCancel} onConfirm={() => onConfirm()} />
  );
};
